import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Divider, Button, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Paper, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SchoolIcon from '@mui/icons-material/School';
import NewsletterWithCard from '../IndexPage/components/NewsletterWithCard/NewsletterWithCard';

// This component is NOT wrapped with withSeasonCheck so it's always visible
const MensCBBHomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Main>
      <Helmet>
        <title>Men's College Basketball Betting Guide: Find Value Bets & Odds | +EV Bets</title>
        <meta name="description" content="Expert NCAA Men's Basketball betting analysis, strategies, and odds comparison. Find the best value bets for college basketball games with our data-driven approach." />
        <meta name="keywords" content="college basketball betting, NCAA basketball betting, March Madness betting, men's CBB odds, college basketball value bets, NCAA betting guide" />
        <link rel="canonical" href="https://plusevbets.com/mens-cbb" />
        <meta property="og:title" content="Men's College Basketball Betting Guide: Find Value Bets & Odds | +EV Bets" />
        <meta property="og:description" content="Expert NCAA Men's Basketball betting analysis, strategies, and odds comparison. Find the best value bets for college basketball games with our data-driven approach." />
        <meta property="og:url" content="https://plusevbets.com/mens-cbb" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsOrganization",
              "name": "NCAA Men's College Basketball",
              "alternateName": "Men's CBB",
              "url": "https://plusevbets.com/mens-cbb",
              "description": "Men's college basketball betting analysis and value bets"
            }
          `}
        </script>
      </Helmet>

      {/* Improved Hero Section */}
      <Box 
        sx={{
          background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 6, md: 10 },
          mb: { xs: 4, md: 6 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box>
                <Typography 
                  variant="h1" 
                  component="h1" 
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '3.5rem' }, 
                    fontWeight: 800, 
                    color: 'white',
                    mb: 2,
                    lineHeight: 1.2
                  }}
                >
                  College Basketball Betting Analysis
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h2" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }, 
                    fontWeight: 400, 
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 4,
                    maxWidth: '90%'
                  }}
                >
                  Data-driven insights to help you find positive expected value in NCAA Men's Basketball betting markets
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Button 
                    component={Link} 
                    to="/mens-cbb/moneyline" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    CBB Moneylines
                  </Button>
                  <Button 
                    component={Link} 
                    to="/mens-cbb/spread" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    CBB Spreads
                  </Button>
                  <Button 
                    component={Link} 
                    to="/mens-cbb/total" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    CBB Totals
                  </Button>
                </Box>
              </Box>
            </Grid>
            {isMd && (
              <Grid item md={5}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box 
                    sx={{
                      width: 300,
                      height: 300,
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <SportsBasketballIcon 
                        sx={{ 
                          fontSize: 180, 
                          color: 'rgba(255, 255, 255, 0.8)'
                        }} 
                      />
                      <SchoolIcon 
                        sx={{ 
                          fontSize: 80, 
                          color: 'rgba(255, 255, 255, 0.9)',
                          position: 'absolute',
                          bottom: -20,
                          right: -20
                        }} 
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '15%',
            minHeight: 50
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        {/* Main Content Grid */}
        <Grid container spacing={4}>
          {/* Left Column - Main Content */}
          <Grid item xs={12} md={8}>
            {/* Introduction Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NCAA Men's Basketball Betting: The Ultimate Guide
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                NCAA Men's College Basketball offers one of the most diverse and opportunity-rich betting environments in sports. With over 350 Division I teams playing thousands of games each season, culminating in the excitement of March Madness, college basketball provides countless opportunities for bettors to find value.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we analyze college basketball betting markets to find positive expected value opportunities. Our data-driven approach helps you identify when the odds are in your favor, giving you an edge over the sportsbooks.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                Whether you're betting on regular season games, conference tournaments, or the NCAA Tournament, our college basketball betting tools provide the insights you need to make informed decisions in this exciting and often unpredictable sport.
              </Typography>
            </Paper>

            {/* CBB Predictions Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                College Basketball Predictions & Analysis
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we provide college basketball betting insights by leveraging high-quality prediction data from trusted external sources. Rather than developing our own predictive model, we focus on analyzing and presenting the most reliable NCAA basketball predictions in an accessible format.
              </Typography>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Our Approach
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Curated Data Sources</Typography>}
                          secondary="We carefully select prediction data from established statistical models"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Market Comparison</Typography>}
                          secondary="We compare predictions against current betting markets"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Value Identification</Typography>}
                          secondary="We highlight where the market may have mispriced outcomes"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Clear Presentation</Typography>}
                          secondary="We present complex data in an easy-to-understand format"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Finding Value
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                      We help you identify value bets by comparing statistical predictions with sportsbook odds. A value bet occurs when:
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1, mb: 2 }}>
                      <Typography sx={{ fontFamily: 'monospace', mb: 1 }}>
                        Predicted Probability {'>'} Implied Probability from Odds
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This suggests the market has undervalued the true likelihood of an outcome
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      By focusing on these discrepancies, you can make more informed betting decisions based on statistical advantage rather than gut feeling.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                For detailed college basketball predictions and value bets, visit our <Link to="/mens-cbb/moneyline" style={{ color: theme.palette.primary.main, fontWeight: 600 }}>CBB Moneyline</Link> page where we present the latest data and highlight potential value opportunities. Our goal is to provide you with the tools and information needed to make smarter betting decisions, even if you're not a statistical expert.
              </Typography>
            </Paper>

            {/* CBB Betting Strategy Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                College Basketball Betting Strategies
              </Typography>
              
              <Accordion defaultExpanded sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Point Spread Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Point spreads are the most popular way to bet on college basketball games, especially with the significant talent disparities between teams.
                  </Typography>
                  <Typography paragraph>
                    <strong>Key factors to consider when betting college basketball spreads:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Home court advantage varies significantly by program" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Pace of play and stylistic matchups" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Team experience and coaching tendencies" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Situational spots (look-ahead games, letdown spots)" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/mens-cbb/spread" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View CBB Spread Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Moneyline Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Moneyline betting in college basketball can offer value, particularly when targeting mid-major upsets or conference tournament situations.
                  </Typography>
                  <Typography paragraph>
                    <strong>Effective moneyline betting approaches:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Identifying undervalued mid-major programs" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Conference tournament motivation factors" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Teams with strong defensive metrics as underdogs" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Late-season motivation disparities" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/mens-cbb/moneyline" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View CBB Moneyline Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Totals (Over/Under) Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    College basketball totals betting requires understanding team tempo, efficiency, and defensive systems that impact scoring.
                  </Typography>
                  <Typography paragraph>
                    <strong>Factors that influence college basketball totals:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Team tempo (possessions per game)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Offensive and defensive efficiency ratings" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Three-point shooting volume and efficiency" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Free throw rate and shooting percentage" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/mens-cbb/total" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View CBB Totals Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Historical Trends Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                College Basketball Betting Trends & Data Analysis
              </Typography>
              <Typography paragraph sx={{ mb: 3 }}>
                Understanding historical college basketball betting trends can provide valuable context for your wagering decisions. While past performance doesn't guarantee future results, certain patterns have shown consistency over time.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Home Court Value
                      </Typography>
                      <Typography variant="body2">
                        Home court advantage in college basketball is typically worth 3-4 points, but varies significantly by program. Teams with strong student sections and attendance often exceed this average.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Conference Tournament Unders
                      </Typography>
                      <Typography variant="body2">
                        Conference tournament games have historically gone under the total at a 55.7% rate, likely due to increased defensive intensity, familiarity between opponents, and neutral court settings.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Mid-Major Value
                      </Typography>
                      <Typography variant="body2">
                        Mid-major conferences are often less efficiently priced than power conferences. Teams from conferences like the Missouri Valley, MAC, and WCC have historically provided better against-the-spread value.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Tournament Seeding Value
                      </Typography>
                      <Typography variant="body2">
                        In the NCAA Tournament, teams seeded 10-12 have historically provided the best value against the spread, covering at a 54.3% rate over the past two decades in first-round matchups.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Right Column - Sidebar */}
          <Grid item xs={12} md={4}>
            {/* Quick Links Card */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                College Basketball Betting Markets
              </Typography>
              <List sx={{ bgcolor: theme.palette.grey[50], borderRadius: 2, overflow: 'hidden' }}>
                <ListItem 
                  component={Link} 
                  to="/mens-cbb/moneyline" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>CBB Moneylines</Typography>} 
                    secondary="Find value in picking straight-up winners" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/mens-cbb/spread" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>CBB Point Spreads</Typography>} 
                    secondary="Analyze handicapped matchups and line value" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/mens-cbb/total" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>CBB Totals</Typography>} 
                    secondary="Over/under predictions based on scoring models" 
                  />
                </ListItem>
              </List>
            </Paper>

            {/* CBB Betting FAQ */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                College Basketball Betting FAQ
              </Typography>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How does betting on college basketball differ from the NBA?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    College basketball betting differs from the NBA in several key ways. There's greater variance in college due to shorter games (40 minutes vs. 48), less consistent shooting, and more dramatic talent disparities. Home court advantage is typically stronger in college, and there's less public information about injuries and lineup changes. These factors create both challenges and opportunities for bettors.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What's the best approach for betting on March Madness?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    For March Madness betting, focus on teams with experienced guards, strong defensive metrics, and efficient three-point shooting. Avoid overreacting to conference tournament results, as they're not always predictive of NCAA Tournament performance. Consider the impact of travel and rest days on performance. For bracket pools, embrace some strategic upsets but don't go overboard—historically, the Final Four typically includes at least two 1-3 seeds.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How important is tempo in college basketball betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Tempo is extremely important in college basketball betting, particularly for totals. The pace of play varies dramatically between teams, from under 60 possessions per game to over 75. When teams with contrasting tempos meet, understanding which team will control the pace is crucial. Teams that can dictate their preferred tempo often outperform expectations. KenPom's adjusted tempo metric is one of the most valuable tools for analyzing these matchups.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What advanced stats are most useful for college basketball betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    The most useful advanced stats for college basketball betting include adjusted efficiency metrics (offensive and defensive efficiency adjusted for competition), effective field goal percentage, turnover rate, offensive rebounding percentage, and free throw rate. These "Four Factors" developed by Dean Oliver provide insight into team strengths and weaknesses. Additionally, three-point attempt rate and three-point defense can help identify potential variance in performance.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
        
        {/* Newsletter CTA Section */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterWithCard />
        </Box>
      </Container>
    </Main>
  );
};

export default MensCBBHomePage; 