/* eslint-disable no-unused-vars */
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const rootElement = document.getElementById('root');
const app = (
  <Auth0Provider
    domain="dev-by8c1wb6a4gh801f.us.auth0.com"
    clientId="TaMzJKjbCctF8MLKRDgTvj8hd8Xufovx"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/auth0-return`,
      audience: 'https://auth.plusevbets.com',
      scope: 'openid profile email offline_access'
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    timeoutInSeconds={259200}
  >
    <App />
  </Auth0Provider>
);

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, app);
} else {
  createRoot(rootElement).render(app);
}

// For react-snap
window.snapSaveState = () => ({
  __PRELOADED_STATE__: {}  // Add your state here if needed
});
