import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button } from '@mui/material';

const ErrorBoundary = ({ children }) => {
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleError = (error) => {
      if (error.status === 401) {
        navigate('/login');
      } else if (error.status === 403) {
        navigate('/pricing');
      } else {
        setError(error);
      }
    };

    // Add global error handler
    window.addEventListener('unhandledrejection', (event) => {
      handleError(event.reason);
    });

    return () => {
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, [navigate]);

  if (error) {
    return (
      <Alert 
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={() => setError(null)}>
            RETRY
          </Button>
        }
      >
        {error.message}
      </Alert>
    );
  }

  return children;
};

export default ErrorBoundary; 