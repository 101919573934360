import React from 'react';
import Typography from '@mui/material/Typography';
import LegalPage from 'components/LegalPage/LegalPage';
import SEO from 'components/SEO';
import seoConfig from '../../config/seo.json';

const Terms = () => {
  return (
    <>
      <SEO {...seoConfig.terms} />
      <LegalPage 
        title="Terms of Service"
        subtitle={`Last modified on ${new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}`}
      >
        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography paragraph>
          By accessing and using +EV Bets, you agree to be bound by these Terms of Service. 
          Our service provides sports betting analytics and information for entertainment and informational purposes only.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Service Description
        </Typography>
        <Typography paragraph>
          +EV Bets provides sports betting analytics, statistics, and insights. We do not accept wagers 
          or provide gambling services. Our content is for informational purposes only.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Obligations
        </Typography>
        <Typography paragraph>
          Users must be of legal age in their jurisdiction. You agree to provide accurate information 
          and to use our service in compliance with applicable laws and regulations.
        </Typography>

        {/* Add more sections as needed */}
      </LegalPage>
    </>
  );
};

export default Terms; 