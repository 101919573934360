import MensCBBmoneyline from './MensCBBmoneyline/MensCBBmoneyline';
import MensCBBspread from './MensCBBspread/MensCBBspread';
import MensCBBtotal from './MensCBBtotal/MensCBBtotal';

const mensCBBRoutes = [
  {
    path: '/mens-cbb/moneyline',
    label: 'Moneylines',
    renderer: (params = {}) => <MensCBBmoneyline {...params} />
  },
  {
    path: '/mens-cbb/spread',
    label: 'Spreads',
    renderer: (params = {}) => <MensCBBspread {...params} />
  },
  {
    path: '/mens-cbb/total',
    label: 'Totals',
    renderer: (params = {}) => <MensCBBtotal {...params} />
  }
];

export default mensCBBRoutes;