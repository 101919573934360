import React from 'react';
import { useSeason } from './seasonContext';
import OffSeason from '../components/OffSeason';

/**
 * Higher-Order Component that checks if a sport is active
 * If active, renders the wrapped component
 * If inactive, renders the OffSeason component
 * 
 * @param {React.Component} WrappedComponent - The component to wrap
 * @param {string} sport - The sport identifier (e.g., 'NFL', 'NBA')
 * @returns {React.Component} - The wrapped component with season checking
 */
export const withSeasonCheck = (WrappedComponent, sport) => {
  const WithSeasonCheck = (props) => {
    const { isSeasonActive } = useSeason();
    
    if (isSeasonActive(sport)) {
      return <WrappedComponent {...props} />;
    }
    
    return <OffSeason sport={sport} />;
  };
  
  // Set display name for debugging
  WithSeasonCheck.displayName = `WithSeasonCheck(${getDisplayName(WrappedComponent)})`;
  
  return WithSeasonCheck;
};

// Helper function to get component display name
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
} 