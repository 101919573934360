import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const iconSx = { 
  fontSize: 40, 
  color: 'primary.main',
  p: 1,
  backgroundColor: 'primary.lighter',
  borderRadius: '50%',
  opacity: 0.9,
  boxShadow: 1
};

const ValueProposition = () => (
  <Box sx={{ mb: 8, textAlign: 'center' }}>
    <Typography variant="h3" sx={{ mb: 2, fontWeight: 700 }}>
      Smart Sports Betting Made Simple
    </Typography>
    
    <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
      Positive Expected Value (+EV) is the foundation of profitable betting. Our algorithms identify bets 
      where the true win probability exceeds what the odds suggest - the key to long-term success.
    </Typography>

    <Grid container spacing={4} justifyContent="center" sx={{ mb: 4 }}>
      <Grid item xs={12} md={4}>
        <Box>
          <Box sx={{ mb: 2 }}>
            <AccessTimeIcon sx={iconSx} />
          </Box>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
            Save Time
          </Typography>
          <Typography color="text.secondary">
            Get instant betting recommendations backed by data. No more hours spent analyzing stats and odds.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box>
          <Box sx={{ mb: 2 }}>
            <ShowChartIcon sx={iconSx} />
          </Box>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
            Data-Driven Decisions
          </Typography>
          <Typography color="text.secondary">
            Our AI processes millions of data points to find high-value betting opportunities others miss.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box>
          <Box sx={{ mb: 2 }}>
            <TrendingUpIcon sx={iconSx} />
          </Box>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
            Proven Results
          </Typography>
          <Typography color="text.secondary">
            Consistently identify +EV bets across multiple sports markets to build your bankroll over time.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default ValueProposition; 