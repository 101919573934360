/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const evConcepts = [
  {
    title: 'What is Expected Value?',
    subtitle:
      'Expected Value (EV) measures the average outcome of a bet over time. When the true probability of winning exceeds the implied probability from odds, that\'s +EV.',
    icon: <ShowChartIcon sx={{ fontSize: 32 }} />
  },
  {
    title: 'Why +EV Matters',
    subtitle:
      'Just like professional investors seek undervalued stocks, smart bettors look for undervalued odds. +EV betting is about finding these opportunities consistently.',
    icon: <TrendingUpIcon sx={{ fontSize: 32 }} />
  },
  {
    title: 'Long-Term Edge',
    subtitle:
      'While any single bet can win or lose, betting +EV consistently gives you a mathematical advantage over time, just like the house edge works for casinos.',
    icon: <TimelineIcon sx={{ fontSize: 32 }} />
  },
  {
    title: 'Finding Value',
    subtitle:
      'Our models analyze thousands of data points to identify when odds don\'t reflect true probabilities, helping you spot +EV opportunities instantly.',
    icon: <AccountBalanceIcon sx={{ fontSize: 32 }} />
  }
];

const Example = () => {
  const theme = useTheme();
  
  return (
    <Card
      sx={{
        mt: 6,
        p: 4,
        background: theme.palette.background.paper,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 600 }}
      >
        +EV Betting Example
      </Typography>
      
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6" color="primary" gutterBottom>
              The Scenario
            </Typography>
            <Typography paragraph>
              Let's say our model predicts Team A has a 60% chance to win, but the sportsbook offers odds of +150 
              (implied probability of 40%).
            </Typography>
            
            <Box sx={{ my: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                The Math:
              </Typography>
              <Box sx={{ pl: 2, mt: 1 }}>
                <Typography>• True Probability: 60%</Typography>
                <Typography>• Implied Probability: 40%</Typography>
                <Typography>• Edge: +20% (This is your advantage)</Typography>
              </Box>
            </Box>

            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Expected Value Calculation:
            </Typography>
            <Box 
              sx={{ 
                bgcolor: 'background.default',
                p: 2,
                borderRadius: 1,
                mt: 1
              }}
            >
              <Typography sx={{ fontFamily: 'monospace' }}>
                EV = (Win% × Profit) - (Loss% × Stake)
              </Typography>
              <Typography sx={{ fontFamily: 'monospace', mt: 1 }}>
                EV = (0.60 × $150) - (0.40 × $100)
              </Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'monospace', 
                  mt: 1,
                  color: 'primary.main',
                  fontWeight: 600
                }}
              >
                EV = +$50 per $100 wagered
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6" color="primary" gutterBottom>
              What This Means
            </Typography>
            
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CheckCircleIcon sx={{ color: 'success.main', mr: 1 }} />
                <Typography>
                  <strong>Positive EV (+$50):</strong> For every $100 bet, you expect to profit $50 in the long run
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CheckCircleIcon sx={{ color: 'success.main', mr: 1 }} />
                <Typography>
                  <strong>Edge (20%):</strong> Your model shows a significant advantage over the market odds
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CancelIcon sx={{ color: 'error.main', mr: 1 }} />
                <Typography>
                  <strong>Important Note:</strong> Individual bets can still lose, but +EV betting wins over time
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
              How We Help:
            </Typography>
            <Typography>
              Our platform automatically identifies these +EV opportunities by comparing our predicted probabilities 
              against current market odds, saving you hours of manual calculations.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

const Benefits = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Understanding +EV Betting
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          sx={{ mb: 2 }}
        >
          The smart bettor's approach to long-term success
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {evConcepts.map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Card
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.background.paper,
              }}
            >
              <Box 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  color: theme.palette.primary.main
                }}
              >
                {item.icon}
                <Typography 
                  variant={'h6'} 
                  sx={{ 
                    fontWeight: 600,
                    ml: 1
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Typography 
                color="text.secondary"
                sx={{ 
                  flex: 1,
                  lineHeight: 1.8 
                }}
              >
                {item.subtitle}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Example />
    </Box>
  );
};

export default Benefits;
