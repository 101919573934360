import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ACTIVE_SEASONS } from '../../config/seasons';
import Main from 'layouts/Main';
import Page from './components/Page/index';
import { Helmet } from 'react-helmet-async';
import seoConfig from '../../config/seo';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
  SportsBaseball as MLBIcon,
  SportsBasketball as NBAIcon,
  SportsHockey as NHLIcon,
  SportsFootball as NFLIcon,
  School as NCAAIcon,
  ListItemIcon,
} from '@mui/icons-material';
import { Navigate } from 'react-router-dom';

const Dashboard = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { title, description, keywords } = seoConfig.dashboard;

  console.log('Dashboard render:', { 
    isAuthenticated, 
    isLoading, 
    pathname: window.location.pathname,
    state: window.history.state 
  });

  // Wait for Auth0 to initialize
  if (isLoading) {
    return (
      <Main>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>
        <Page>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        </Page>
      </Main>
    );
  }

  // Redirect if not authenticated
  if (!isAuthenticated) {
    console.log('Dashboard: Not authenticated, redirecting to home');
    return <Navigate to="/" />;
  }

  console.log('Dashboard: Rendering main content');

  const sportMarkets = {
    NBA: {
      title: 'NBA',
      icon: NBAIcon,
      markets: [
        { name: 'Moneylines', path: '/nba/moneyline' },
        { name: 'Spreads', path: '/nba/spread' },
        { name: 'Totals', path: '/nba/total' },
      ],
    },
    NHL: {
      title: 'NHL',
      icon: NHLIcon,
      markets: [
        { name: 'Moneylines', path: '/nhl/moneyline' },
        { name: 'Spreads', path: '/nhl/spread' },
        { name: 'Totals', path: '/nhl/total' },
      ],
    },
    MLB: {
      title: 'MLB',
      icon: MLBIcon,
      markets: [
        { name: 'Moneylines', path: '/mlb/moneyline' },
        { name: 'Spreads', path: '/mlb/spread' },
        { name: 'Totals', path: '/mlb/total' },
      ],
    },
    NFL: {
      title: 'NFL',
      icon: NFLIcon,
      markets: [
        { name: 'Moneylines', path: '/nfl/moneyline' },
        { name: 'Spreads', path: '/nfl/spread' },
        { name: 'Totals', path: '/nfl/total' },
      ],
    },
    NCAAF: {
      title: 'NCAA Football',
      icon: NCAAIcon,
      markets: [
        { name: 'Moneylines', path: '/ncaaf/moneyline' },
        { name: 'Spreads', path: '/ncaaf/spread' },
        { name: 'Totals', path: '/ncaaf/total' },
      ],
    },
    MENSCBB: {
      title: 'NCAA Men\'s Basketball',
      icon: NCAAIcon,
      markets: [
        { name: 'Moneylines', path: '/mens-cbb/moneyline' },
        { name: 'Spreads', path: '/mens-cbb/spread' },
        { name: 'Totals', path: '/mens-cbb/total' },
      ],
    },
  };

  return (
    <Main>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Page>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom fontWeight={600}>
                    An Overview of How to Use +EV Bets
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" paragraph>
                      We identify games where the true probability differs from what the sportsbooks are offering, creating positive expected value opportunities.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                      Quick Start Guide:
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemText 
                          primary="1. Choose Your Market" 
                          secondary="Select a sport and market type below (moneyline, spread, or total)"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary="2. Find +EV Opportunities" 
                          secondary="Look for games where our projected odds show an edge over the sportsbook lines, they'll be in green"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary="3. Do Your Research" 
                          secondary="Review team news, injuries, and other factors that could impact the game"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary="4. Place Your Bets" 
                          secondary="Make informed decisions based on the data and your research"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                      Note: We're currently working on adding new features to this dashboard. Thanks for your patience!
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {Object.entries(sportMarkets).map(([sport, data]) => (
              ACTIVE_SEASONS[sport] && (
                <Grid item xs={12} md={6} key={sport}>
                  <Card>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        {data.icon && (
                          <Box mr={2} color="primary.main">
                            <data.icon fontSize="large" />
                          </Box>
                        )}
                        <Typography variant="h6" fontWeight={600}>
                          {data.title}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <List>
                          {data.markets.map((market) => (
                            <ListItem 
                              key={market.path} 
                              component={Link} 
                              to={market.path}
                              sx={{
                                borderRadius: 1,
                                py: 1.5,
                                px: 2,
                                mb: 1,
                                border: 1,
                                borderColor: 'divider',
                                '&:hover': {
                                  backgroundColor: 'action.hover',
                                  color: 'primary.main',
                                  borderColor: 'primary.main',
                                  transform: 'translateY(-1px)',
                                  transition: 'all .2s ease-in-out',
                                },
                                textDecoration: 'none',
                                color: 'text.primary',
                              }}
                            >
                              <ListItemText 
                                primary={market.name}
                                primaryTypographyProps={{
                                  fontWeight: 500,
                                  color: 'primary.main',
                                }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
            ))}
          </Grid>
        </Box>
      </Page>
    </Main>
  );
};

export default Dashboard; 