/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import seoConfig from '../config/seo.json';

const SEO = ({ title, description, keywords, og, children }) => {
  const location = useLocation();
  const currentUrl = `https://plusevbets.com${location.pathname}`;
  
  // Get OG data with fallbacks
  const ogData = og || {
    title: title,
    description: description,
    image: 'https://plusevbets.com/og-image.jpg',
    url: currentUrl
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogData.url} />
      <meta property="og:title" content={ogData.title} />
      <meta property="og:description" content={ogData.description} />
      <meta property="og:image" content={ogData.image} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={ogData.url} />
      <meta name="twitter:title" content={ogData.title} />
      <meta name="twitter:description" content={ogData.description} />
      <meta name="twitter:image" content={ogData.image} />

      {/* Additional SEO tags */}
      <link rel="canonical" href={ogData.url} />

      {children}
    </Helmet>
  );
};

export default SEO;