
// API URL config
const DEFAULT_API_URL = 'http://localhost:5000';
const PROD_API_URL = 'https://plusevbets.com';

export const API_URL = process.env.NODE_ENV === 'production' 
  ? PROD_API_URL 
  : (process.env.REACT_APP_API_URL || DEFAULT_API_URL);

// console.log('NODE_ENV:', process.env.NODE_ENV);
// console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
// console.log('Final API_URL:', API_URL);

// console.log('Environment:', process.env.NODE_ENV);
// console.log('API_URL from env:', process.env.REACT_APP_API_URL);
// console.log('Using API_URL:', API_URL); 