/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import UpdateIcon from '@mui/icons-material/Update';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const features = [
  {
    title: 'Real-Time Odds',
    description: 'Live odds monitoring from FanDuel and DraftKings, updated every 15 minutes',
    icon: UpdateIcon,
    color: '#2196f3'  // blue
  },
  {
    title: 'Odds Comparison',
    description: 'Compare odds across sportsbooks to find the best value for each bet',
    icon: CompareArrowsIcon,
    color: '#4caf50'  // green
  },
  {
    title: '+EV Detection',
    description: 'Advanced algorithms identify bets with positive expected value',
    icon: TrendingUpIcon,
    color: '#f44336'  // red
  },
  {
    title: 'Time Saving',
    description: 'Skip hours of manual research with our automated analysis',
    icon: AccessTimeIcon,
    color: '#ff9800'  // orange
  }
];

const Features = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Why Choose +EV Bets
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color="text.secondary"
          sx={{ mb: 6 }}
        >
          Make smarter betting decisions with our powerful features
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {features.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Card
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.background.paper,
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-8px)'
                }
              }}
            >
              <Box
                sx={{
                  p: 2,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: `${item.color}10`,
                  borderRadius: 2,
                  width: 'fit-content'
                }}
              >
                <item.icon sx={{ fontSize: 40, color: item.color }} />
              </Box>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 600 }}
              >
                {item.title}
              </Typography>
              <Typography
                color="text.secondary"
              >
                {item.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
