import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const premiumFeatures = [
  'NBA Analytics and Predictions',
  'MLB Analytics and Insights',
  'NCAA Sports Coverage',
  'Advanced Statistics',
  'Real-time Updates',
  'Premium Support'
];

const Upgrade = () => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get the selected plan from location state or localStorage
  const appState = JSON.parse(localStorage.getItem('auth0_app_state') || '{}');
  const selectedPlan = location.state?.plan || appState?.plan || 'Premium';
  const isAnnual = location.state?.isAnnual ?? true;

  const planDetails = {
    name: 'Premium',
    price: isAnnual ? '$240' : '$25',
    period: isAnnual ? '/year' : '/month',
    savings: isAnnual ? 'Save $60/year' : null
  };

  const handleUpgrade = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          plan: selectedPlan,
          isAnnual,
          userId: user.sub,
          returnUrl: `${window.location.origin}/upgrade/success`
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }
      
      const { sessionUrl } = await response.json();
      window.location.href = sessionUrl;
    } catch (err) {
      setError('Failed to start checkout. Please try again.');
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <Container>
        <Box textAlign="center" py={4}>
          <Typography variant="h4" gutterBottom>
            Please log in to upgrade
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => loginWithRedirect({
              appState: { returnTo: '/upgrade', plan: selectedPlan }
            })}
          >
            Log In
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box py={4}>
        <Grid container spacing={4}>
          {/* Left side - Plan Details */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Upgrade to {planDetails.name}
            </Typography>
            <Typography color="text.secondary" paragraph>
              Welcome {user?.name}! You're about to unlock all our premium features.
            </Typography>
            
            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Box display="flex" alignItems="baseline" mb={2}>
                  <Typography variant="h3" component="span">
                    {planDetails.price}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" ml={1}>
                    {planDetails.period}
                  </Typography>
                </Box>
                {planDetails.savings && (
                  <Typography color="success.main" gutterBottom>
                    {planDetails.savings}
                  </Typography>
                )}
                <List>
                  {premiumFeatures.map((feature, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleUpgrade}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `Complete Upgrade - ${planDetails.price}${planDetails.period}`
              )}
            </Button>
          </Grid>

          {/* Right side - What you'll get */}
          <Grid item xs={12} md={6}>
            <Box bgcolor="background.paper" p={3} borderRadius={2}>
              <Typography variant="h5" gutterBottom>
                What's included in Premium
              </Typography>
              <Typography paragraph color="text.secondary">
                Get instant access to all our premium features and analytics
              </Typography>
              {/* Add more detailed feature descriptions */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Upgrade; 