import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SpeedIcon from '@mui/icons-material/Speed';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import PsychologyIcon from '@mui/icons-material/Psychology';

const services = [
  {
    title: 'Win More, Research Less',
    description: 'Our platform uses predictive analytics to identify bets with the best value, so you can enjoy the game without catching flack.',
    icon: AutoGraphIcon,
  },
  {
    title: 'Smart Insights for Efficient Betting',
    description: 'Your time is precious. That\'s why we deliver quick, data-driven recommendations on the most promising wagers.',
    icon: SpeedIcon,
  },
  {
    title: 'User-Friendly Interface',
    description: 'Our intuitive design ensures you can find the best betting options fast. You\'re always just moments away from identifying your next potential win.',
    icon: TouchAppIcon,
  },
  {
    title: 'Tailored Analytics',
    description: 'We cater to the parents looking for an edge. Our platform is your shortcut to smart betting, offering a clear view of profitable bets.',
    icon: PsychologyIcon,
  }
];

const Services = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          {/* Smart Betting Made Simple */}
          Win More, Research Less with +EV Bets
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color="text.secondary"
          sx={{ mb: 6 }}
        >
          Stop spending hours on research. Start making data-driven decisions.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {services.map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Card
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.background.paper,
                '&:hover': {
                  boxShadow: 2,
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 2
                }}
              >
                <item.icon 
                  sx={{ 
                    fontSize: 40, 
                    color: theme.palette.primary.main,
                    marginRight: 2 
                  }} 
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ 
                    fontWeight: 600,
                    marginBottom: 0 
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Typography
                color="text.secondary"
              >
                {item.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services; 