import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Divider, Button, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Paper, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import NewsletterWithCard from '../IndexPage/components/NewsletterWithCard/NewsletterWithCard';

// This component is NOT wrapped with withSeasonCheck so it's always visible
const NHLHomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Main>
      <Helmet>
        <title>NHL Betting Guide: Find Value Bets & Odds | +EV Bets</title>
        <meta name="description" content="Expert NHL betting analysis, strategies, and odds comparison. Find the best value bets for NHL games with our data-driven approach to hockey wagering." />
        <meta name="keywords" content="NHL betting, hockey betting strategy, NHL odds, NHL value bets, NHL betting guide, NHL betting tips" />
        <link rel="canonical" href="https://plusevbets.com/nhl" />
        <meta property="og:title" content="NHL Betting Guide: Find Value Bets & Odds | +EV Bets" />
        <meta property="og:description" content="Expert NHL betting analysis, strategies, and odds comparison. Find the best value bets for NHL games with our data-driven approach to hockey wagering." />
        <meta property="og:url" content="https://plusevbets.com/nhl" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsOrganization",
              "name": "National Hockey League",
              "alternateName": "NHL",
              "url": "https://plusevbets.com/nhl",
              "description": "NHL betting analysis and value bets"
            }
          `}
        </script>
      </Helmet>

      {/* Improved Hero Section */}
      <Box 
        sx={{
          background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 6, md: 10 },
          mb: { xs: 4, md: 6 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box>
                <Typography 
                  variant="h1" 
                  component="h1" 
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '3.5rem' }, 
                    fontWeight: 800, 
                    color: 'white',
                    mb: 2,
                    lineHeight: 1.2
                  }}
                >
                  NHL Betting Analysis & Value Finder
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h2" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }, 
                    fontWeight: 400, 
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 4,
                    maxWidth: '90%'
                  }}
                >
                  Data-driven insights to help you find positive expected value in NHL betting markets
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Button 
                    component={Link} 
                    to="/nhl/moneyline" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NHL Moneylines
                  </Button>
                  <Button 
                    component={Link} 
                    to="/nhl/spread" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NHL Puck Lines
                  </Button>
                  <Button 
                    component={Link} 
                    to="/nhl/total" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NHL Totals
                  </Button>
                </Box>
              </Box>
            </Grid>
            {isMd && (
              <Grid item md={5}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box 
                    sx={{
                      width: 300,
                      height: 300,
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <SportsHockeyIcon 
                      sx={{ 
                        fontSize: 180, 
                        color: 'rgba(255, 255, 255, 0.8)'
                      }} 
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '15%',
            minHeight: 50
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        {/* Main Content Grid */}
        <Grid container spacing={4}>
          {/* Left Column - Main Content */}
          <Grid item xs={12} md={8}>
            {/* Introduction Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NHL Betting: The Ultimate Guide
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                The National Hockey League (NHL) offers a unique betting landscape characterized by tight margins, low-scoring games, and significant variance. With 82 regular season games per team, the NHL provides numerous opportunities for bettors to find value.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we analyze NHL betting markets to find positive expected value opportunities. Our data-driven approach helps you identify when the odds are in your favor, giving you an edge over the sportsbooks.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                Whether you're betting on moneylines, puck lines, totals, or player props, our NHL betting tools provide the insights you need to make informed decisions in this fast-paced, unpredictable sport.
              </Typography>
            </Paper>

            {/* NHL Predictions Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NHL Predictions & Analysis
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we provide NHL betting insights by leveraging high-quality prediction data from trusted external sources. Rather than developing our own predictive model, we focus on analyzing and presenting the most reliable NHL predictions in an accessible format.
              </Typography>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Our Approach
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Curated Data Sources</Typography>}
                          secondary="We carefully select prediction data from established statistical models"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Market Comparison</Typography>}
                          secondary="We compare predictions against current betting markets"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Value Identification</Typography>}
                          secondary="We highlight where the market may have mispriced outcomes"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Clear Presentation</Typography>}
                          secondary="We present complex data in an easy-to-understand format"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Finding Value
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                      We help you identify value bets by comparing statistical predictions with sportsbook odds. A value bet occurs when:
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1, mb: 2 }}>
                      <Typography sx={{ fontFamily: 'monospace', mb: 1 }}>
                        Predicted Probability {'>'} Implied Probability from Odds
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This suggests the market has undervalued the true likelihood of an outcome
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      By focusing on these discrepancies, you can make more informed betting decisions based on statistical advantage rather than gut feeling.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                For detailed NHL predictions and value bets, visit our <Link to="/nhl/moneyline" style={{ color: theme.palette.primary.main, fontWeight: 600 }}>NHL Moneyline</Link> page where we present the latest data and highlight potential value opportunities. Our goal is to provide you with the tools and information needed to make smarter betting decisions, even if you're not a statistical expert.
              </Typography>
            </Paper>

            {/* NHL Betting Strategy Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NHL Betting Strategies
              </Typography>
              
              <Accordion defaultExpanded sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Moneyline Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Moneyline betting is particularly popular in NHL due to the prevalence of close games and the impact of overtime/shootouts on point spreads.
                  </Typography>
                  <Typography paragraph>
                    <strong>Key factors to consider when betting NHL moneylines:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Goaltender matchups and recent performance" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Team rest situations and travel schedules" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Special teams performance (power play and penalty kill)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Advanced metrics like expected goals and high-danger chances" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nhl/moneyline" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NHL Moneyline Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Puck Line Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Puck lines (hockey's version of point spreads) are typically set at +/- 1.5 goals, offering different value opportunities than moneylines.
                  </Typography>
                  <Typography paragraph>
                    <strong>Effective puck line betting approaches:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Underdogs +1.5 often provide value due to the low-scoring nature of hockey" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Consider team's performance in one-goal games" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Analyze empty-net goal scoring and prevention rates" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Look for teams that consistently win or lose by multiple goals" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nhl/spread" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NHL Puck Line Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Totals (Over/Under) Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    NHL totals betting requires understanding both team playing styles and goaltender performance that impact scoring.
                  </Typography>
                  <Typography paragraph>
                    <strong>Factors that influence NHL totals:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Goaltender quality and recent performance" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Team pace of play and offensive/defensive systems" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Special teams efficiency and penalty tendencies" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Back-to-back situations affecting scoring" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nhl/total" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NHL Totals Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Historical Trends Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NHL Betting Trends & Data Analysis
              </Typography>
              <Typography paragraph sx={{ mb: 3 }}>
                Understanding historical NHL betting trends can provide valuable context for your wagering decisions. While past performance doesn't guarantee future results, certain patterns have shown consistency over time.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Home Favorites After Loss
                      </Typography>
                      <Typography variant="body2">
                        Home teams coming off a loss as favorites have historically bounced back at a rate higher than the market implies, particularly when facing a team on the second night of a back-to-back.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Overtime/Shootout Impact
                      </Typography>
                      <Typography variant="body2">
                        Approximately 23% of NHL games go to overtime or shootout, creating value on underdogs at +1.5 on the puck line, as they only need to force overtime to cover the spread.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Goaltender Fatigue
                      </Typography>
                      <Typography variant="body2">
                        Starting goaltenders playing on consecutive days show a significant performance decline, with save percentage dropping an average of 1.2% in the second game, creating value on overs.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Third Period Scoring
                      </Typography>
                      <Typography variant="body2">
                        NHL games average 35% more goals in the third period compared to the first period, particularly when teams are trailing, making live betting on overs valuable in close games.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Right Column - Sidebar */}
          <Grid item xs={12} md={4}>
            {/* Quick Links Card */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                NHL Betting Markets
              </Typography>
              <List sx={{ bgcolor: theme.palette.grey[50], borderRadius: 2, overflow: 'hidden' }}>
                <ListItem 
                  component={Link} 
                  to="/nhl/moneyline" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NHL Moneylines</Typography>} 
                    secondary="Find value in picking straight-up winners" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/nhl/spread" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NHL Puck Lines</Typography>} 
                    secondary="Analyze handicapped matchups and line value" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/nhl/total" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NHL Totals</Typography>} 
                    secondary="Over/under predictions based on scoring models" 
                  />
                </ListItem>
              </List>
            </Paper>

            {/* NHL Betting FAQ */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                NHL Betting FAQ
              </Typography>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How important are goaltenders in NHL betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Goaltenders are the most influential factor in NHL betting lines. An elite goaltender can shift moneyline odds by 30-40 points compared to a backup. However, it's important to look beyond basic stats like wins and goals against average. Advanced metrics like goals saved above expected (GSAx) and high-danger save percentage provide better predictive value for future performance.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How should I approach NHL puck line betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    NHL puck line betting requires a different approach than moneyline betting. Since approximately 25% of NHL games are decided by exactly one goal, underdogs at +1.5 have inherent value but typically come at a premium price (-200 or higher). Look for situations where a team has a tendency to win or lose by multiple goals, and consider the impact of empty-net situations in the final minutes.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What's the best approach for NHL live betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    NHL live betting offers excellent opportunities due to the fast-paced nature of hockey and the impact of momentum shifts. Look for situations where a team is trailing but dominating in shots and scoring chances, as these teams often provide value. Also consider the impact of power plays, as a team about to go on a power play has increased scoring potential that may not be fully reflected in live odds.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How do advanced stats help with NHL betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Advanced stats like Corsi (shot attempt differential), expected goals (xG), and high-danger chances provide more predictive value than traditional stats like goals and shots. Teams with positive differentials in these metrics but negative goal differentials often represent value, as they're likely to see positive regression. Similarly, teams "winning ugly" with poor underlying metrics but good results are candidates for negative regression.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
        
        {/* Newsletter CTA Section */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterWithCard />
        </Box>
      </Container>
    </Main>
  );
};

export default NHLHomePage; 