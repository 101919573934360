import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../../../../contexts/UserContext';

const features = [
  'Real-time betting analytics',
  'Advanced statistical models',
  'Time-saving recommendations',
  'All major sports coverage',
  'Premium support'
];

const Plans = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { user } = useUser();
  const [loading] = React.useState(false);
  const [pricingOption, setPricingOption] = useState('monthly');

  const handlePricingChange = (event, newPricingOption) => {
    if (newPricingOption !== null) {
      setPricingOption(newPricingOption);
    }
  };

  const price = pricingOption === 'annual' ? '$240' : '$25';
  const period = pricingOption === 'annual' ? 'per year' : 'per month';

  const handleSubscribe = async () => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname }
      });
      return;
    }
    
    if (user?.isPremium) {
      return;
    }
    
    window.location.href = '/upgrade';
  };

  const getButtonText = () => {
    if (loading) return <CircularProgress size={24} />;
    if (user?.isPremium) return 'Current Plan';
    return 'Get Started Today';
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Unlock Smart Betting
        </Typography>
        <Typography variant="h6" align={'center'} color={'text.secondary'}>
          Make data-driven decisions without the time investment
        </Typography>
      </Box>
      
      <Box 
        display={'flex'} 
        justifyContent={'center'} 
        marginBottom={4}
        sx={{
          p: 1,
          borderRadius: 1,
          backgroundColor: theme.palette.background.paper,
          boxShadow: 1
        }}
      >
        <ToggleButtonGroup value={pricingOption} exclusive onChange={handlePricingChange}>
          <ToggleButton
            value="monthly"
            size={isMd ? 'large' : 'small'}
            sx={{
              backgroundColor:
                pricingOption === 'monthly'
                  ? `${theme.palette.primary.light} !important`
                  : 'transparent',
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 700,
                color:
                  pricingOption === 'monthly' ? 'common.white' : 'text.primary',
              }}
            >
              Monthly
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="annual"
            size={isMd ? 'large' : 'small'}
            sx={{
              backgroundColor:
                pricingOption === 'annual'
                  ? `${theme.palette.primary.light} !important`
                  : 'transparent',
              border: `1px solid ${theme.palette.primary.main}`,
              ml: -1
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 700,
                color:
                  pricingOption === 'annual' ? 'common.white' : 'text.primary',
              }}
            >
              Annual • Save 20%
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box
            height={1}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            padding={4}
            border={1}
            borderColor="primary.main"
            borderRadius={2}
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 2
            }}
          >
            <Typography variant={'h5'} fontWeight={600} gutterBottom>
              Premium Access
            </Typography>
            <Typography variant={'h3'} fontWeight={700} gutterBottom>
              {price}
            </Typography>
            <Typography color="text.secondary" align="center" gutterBottom>
              {period}
            </Typography>
            {pricingOption === 'annual' && (
              <Typography 
                color="success.main" 
                variant="subtitle2" 
                fontWeight={600} 
                gutterBottom
              >
                Save $60 with annual billing
              </Typography>
            )}
            
            <Box sx={{ my: 3, width: '100%' }}>
              {features.map((feature, i) => (
                <Box 
                  key={i} 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    mb: 1.5 
                  }}
                >
                  <CheckCircleOutlineIcon 
                    sx={{ 
                      mr: 1,
                      color: 'primary.main'
                    }} 
                  />
                  <Typography>
                    {feature}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubscribe}
              disabled={loading || user?.isPremium}
              sx={{
                py: 1.5,
                fontSize: '1.1rem'
              }}
            >
              {getButtonText()}
            </Button>
            
            <Typography 
              variant="body2" 
              color="text.secondary" 
              align="center" 
              sx={{ mt: 2 }}
            >
              No long-term commitment required
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Plans;