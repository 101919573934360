import { USER_STATES } from '../contexts/UserContext';

export const HERO_CONTENT = {
  [USER_STATES.BROWSING]: {
    headline: 'Find Profitable Bets',
    subheadline: 'Without the Research',
    description: 'Our data-driven approach helps you identify +EV betting opportunities in minutes, not hours.',
    benefits: [
      'Near real-time odds from FanDuel & DraftKings',
      'Advanced statistical models',
      'Identify +EV betting opportunities',
      'Save hours of research time',
      'Future discounts on premium features' //make sure to change when premium features are added
    ],
    showFreeTrial: true
  },
  [USER_STATES.FREE]: {
    headline: 'Hello Smart Bettor',
    subheadline: 'Let\'s find your winning bets',
    description: 'Enjoy limited time full-access to our future premium features.',
    benefits: [
      'Updated odds from FanDuel & DraftKings',
      'Moneyline, Over/Under, and Spread markets',
      'Exclusive identified +EV bets',
      'All for free'
    ],
    showFreeTrial: false
  },
  [USER_STATES.PREMIUM]: {
    headline: 'Welcome Back',
    subheadline: 'Your Premium Dashboard',
    description: 'Access your premium features and latest betting opportunities.',
    benefits: [
      'Real-time premium alerts',
      'Advanced analytics dashboard',
      'Personalized betting insights',
      'Priority support'
    ],
    showFreeTrial: false
  }
};

export const CTA_CONTENT = {
  [USER_STATES.BROWSING]: {
    primary: {
      text: 'Join For Free',
      action: '/signup',
      returnTo: '/dashboard'
    },
    secondary: {
      text: 'Learn More',
      action: '/about',
      returnTo: '/dashboard'
    }
  },
  [USER_STATES.FREE]: {
    primary: {
      text: 'View Today\'s Games', //make sure to change when premium features are added
      action: '/dashboard',
      returnTo: '/dashboard'
    },
    secondary: {
      text: 'View Dashboard',
      action: '/dashboard',
      returnTo: '/dashboard'
    }
  },
  [USER_STATES.PREMIUM]: {
    primary: {
      text: 'View Today\'s Bets',
      action: '/premium',
      returnTo: '/dashboard'
    },
    secondary: {
      text: 'Manage Subscription',
      action: '/account',
      returnTo: '/dashboard'
    }
  }
}; 