import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet-async';
import seoConfig from '../../config/seo';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Main from 'layouts/Main';
import Page from './components/Page';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Alert, Snackbar } from '@mui/material';
import { useUser } from 'contexts/UserContext';

const Account = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { status } = useUser();
  const [testResult, setTestResult] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { title, description, keywords } = seoConfig.account;

  const getMembershipLevel = () => {
    if (!isAuthenticated) return 'Not a member';
    // Later we can check for premium status
    // if (user.isPremium) return 'Premium Member';
    return 'Rookie (Standard Access)';
  };

  const testLoops = async (scenario = 'default') => {
    try {
      let testData = {
        email: user?.email,
        firstName: user?.given_name || user?.name,
        source: 'account_page'
      };

      // Add scenario-specific data
      switch (scenario) {
        case 'signup':
          testData = {
            ...testData,
            status: 'new_signup',
            signupDate: new Date().toISOString(),
            preferredSports: ['NFL', 'NBA'],
            marketingConsent: true
          };
          break;

        case 'confirm_email':
          testData = {
            ...testData,
            status: 'email_confirmed',
            confirmDate: new Date().toISOString(),
            emailVerified: true
          };
          break;

        case 'start_trial':
          testData = {
            ...testData,
            subscriptionStatus: 'trialing',
            trialStartDate: new Date().toISOString(),
            trialEndDate: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(), // 14 days
            planName: 'Premium'
          };
          break;

        case 'subscribe':
          testData = {
            ...testData,
            subscriptionStatus: 'active',
            subscriptionDate: new Date().toISOString(),
            planName: 'Premium',
            previousStatus: 'trialing'
          };
          break;

        case 'cancel':
          testData = {
            ...testData,
            subscriptionStatus: 'canceled',
            cancelDate: new Date().toISOString(),
            cancelReason: 'test_cancellation'
          };
          break;

        case 'reactivate':
          testData = {
            ...testData,
            subscriptionStatus: 'active',
            reactivationDate: new Date().toISOString(),
            previousStatus: 'canceled'
          };
          break;

        default:
          testData = {
            ...testData,
            status: 'test_from_ui',
            testTimestamp: new Date().toISOString()
          };
      }

      const response = await fetch('/api/test-loops', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(testData)
      });
      
      const data = await response.json();
      console.log('Loops test response:', data);
      setTestResult(data);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error testing Loops:', error);
      setTestResult({ status: 'error', message: error.message });
      setSnackbarOpen(true);
    }
  };

  if (!isAuthenticated) {
    return (
      <Main>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>
        <Page>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        </Page>
      </Main>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Main>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Page>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom fontWeight={600}>
                    Profile Details
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography paragraph>
                      <strong>Name:</strong> {user?.nickname || user?.name}
                    </Typography>
                    <Typography paragraph>
                      <strong>Email:</strong> {user?.email}
                      {!user?.email_verified && (
                        <Typography 
                          component="span" 
                          color="warning.main" 
                          sx={{ ml: 1, fontSize: '0.875rem' }}
                        >
                          (Not verified)
                        </Typography>
                      )}
                    </Typography>
                    <Typography paragraph>
                      <strong>Member Since:</strong> {formatDate(user?.updated_at)}
                    </Typography>
                    <Typography paragraph>
                      <strong>Membership Level:</strong> {getMembershipLevel()}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Account Actions */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom fontWeight={600}>
                    Account Actions
                  </Typography>
                  <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button 
                      variant="outlined" 
                      color="primary"
                      onClick={() => logout({ 
                        logoutParams: { returnTo: window.location.origin } 
                      })}
                    >
                      Sign Out
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Only show dev tools in development */}
            {process.env.NODE_ENV === 'development' && (
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom fontWeight={600}>
                      Developer Testing
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                      <Button 
                        variant="contained" 
                        onClick={() => testLoops('signup')}
                        color="success"
                      >
                        Test Signup
                      </Button>
                      <Button 
                        variant="contained" 
                        onClick={() => testLoops('confirm_email')}
                        color="info"
                      >
                        Test Confirmation
                      </Button>
                      <Button 
                        variant="contained" 
                        onClick={() => testLoops('start_trial')}
                        color="primary"
                      >
                        Test Trial Start
                      </Button>
                      <Button 
                        variant="contained" 
                        onClick={() => testLoops('subscribe')}
                        color="success"
                      >
                        Test Subscribe
                      </Button>
                      <Button 
                        variant="contained" 
                        onClick={() => testLoops('cancel')}
                        color="warning"
                      >
                        Test Cancel
                      </Button>
                      <Button 
                        variant="contained" 
                        onClick={() => testLoops('reactivate')}
                        color="info"
                      >
                        Test Reactivate
                      </Button>
                    </Box>
                    {testResult && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Last Test Result:
                        </Typography>
                        <pre style={{ 
                          overflow: 'auto', 
                          background: '#f5f5f5', 
                          padding: '8px',
                          borderRadius: '4px'
                        }}>
                          {JSON.stringify(testResult, null, 2)}
                        </pre>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert 
            onClose={() => setSnackbarOpen(false)} 
            severity={testResult?.status === 'success' ? 'success' : 'error'}
          >
            {testResult?.message}
          </Alert>
        </Snackbar>
      </Page>
    </Main>
  );
};

export default Account;
