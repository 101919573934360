import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { renderFormattedTime, renderFormattedDate, groupLogs, formatTimeAgo, formatDateForTooltip } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Alert, Card, CardContent, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useApi } from 'services/api';
import { ProtectedColumn } from 'components/ProtectedColumn';
import SEO from 'components/SEO';
import seoConfig from '../../../config/seo.json';
import NavTabs from 'components/NavTabs';
import nhlRoutes from '../nhlRoutes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import InfoIcon from '@mui/icons-material/Info';
import { withSeasonCheck } from '../../../config/withSeasonCheck';
import { Link } from 'react-router-dom';
import SportNavTabs from '../../../components/SportNavTabs';


const NHLPage = () => {
  const { callApi } = useApi();
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await callApi('/api/nhlgamelog');
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);
        
        // Set the last update timestamp from the first record if available
        if (data.length > 0 && data[0].last_api_update) {
          setLastUpdateTimestamp(data[0].last_api_update);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [callApi]);

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  const firstBookmaker = firstGroup && firstGroup[0] ? firstGroup[0].bookmaker : null;
  const secondBookmaker = firstGroup && firstGroup[1] ? firstGroup[1].bookmaker : null;
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };
  
  // Check if we have model data
  const hasModelData = logs.length > 0 && logs.some(log => log.model_status !== 'pending');

  return (
    <>
      <SEO {...seoConfig.nhl.moneyline} />
      <Box sx={{ overflowX: 'hidden' }}>
        <Main bgcolor={'background.paper'}>
          <Container>
            <div>
              <header>
                <h1>NHL Moneylines</h1>
                <p>Projected win percentages from <a href="https://moneypuck.com/" target="_blank" rel="noopener noreferrer">MoneyPuck</a>, one of the most respected models for NHL predictions.</p>
                <p>Expected Value (EV) calculated by converting the sportsbook's moneyline and comparing to the MoneyPuck projected win percentage.</p>
                <p>Numbers in green suggest positive expected value from a betting perspective, these are +EV Bets.</p>
              </header>
              <Typography variant="body2" component="p" sx={{ fontStyle: 'italic' }}>
                Lines updated {lastUpdateTimestamp ? formatDateForTooltip(lastUpdateTimestamp) : 'unknown time'}. Updates every 2-4 hours, more frequent closer to game time.
              </Typography>
              {!hasModelData && logs.length > 0 && (
                <p><i><AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} /> Model predictions normally available ~7-8am EST. Odds data is displayed below.</i></p>
              )}
            </div>
            <div>
              <SportNavTabs />
              <NavTabs routes={nhlRoutes} />
            </div>
            {logs.length === 0 ? (
              <div><b>Please check back soon, currently waiting on the next MoneyPuck update. Lines will be available soon after.</b></div>
            ) : (
              <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
                <Table sx={{ minWidth: 400}} aria-label="nhl-moneyline-table">
                  <TableHead sx={{ bgcolor: 'grey.100' }}>
                    <TableRow>
                      <TableCell style={{ verticalAlign: 'top' }}><strong>Game</strong></TableCell>
                      <TableCell {...headerStyling}><strong>MoneyPuck<br />Win%</strong></TableCell>
                      <TableCell {...headerStyling}><strong>{firstBookmaker}</strong></TableCell>
                      <TableCell {...headerStyling}><strong>{firstBookmaker}<br />EV</strong></TableCell>
                      <TableCell {...headerStyling}><strong>{secondBookmaker}</strong></TableCell>
                      <TableCell {...headerStyling}><strong>{secondBookmaker}<br />EV</strong></TableCell>
                      <TableCell {...headerStyling}><strong>Puck Drop</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="stripedTable">
                    {groupLogs(logs).map((gameLogs, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography variant={typographyVariant}>{gameLogs[0]?.away_team_mascot || gameLogs[0]?.the_away_team}</Typography>
                          <Typography variant={typographyVariant}>{gameLogs[0]?.home_team_mascot || gameLogs[0]?.the_home_team}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <>
                              <Typography variant={typographyVariant}>{gameLogs[0].away_team_win_prob ? `${Math.round(gameLogs[0].away_team_win_prob * 100)}%` : '--'}</Typography>
                              <Typography variant={typographyVariant}>{gameLogs[0].home_team_win_prob ? `${Math.round(gameLogs[0].home_team_win_prob * 100)}%` : '--'}</Typography>
                            </>
                          ) : (
                            <Tooltip title="MoneyPuck projections available ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>{gameLogs[0].away_team_price > 0 ? '+' : ''}{gameLogs[0].away_team_price}</Typography>
                          <Typography variant={typographyVariant}>{gameLogs[0].home_team_price > 0 ? '+' : ''}{gameLogs[0].home_team_price}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn
                              feature="view_ev_data"
                              tooltipText="Upgrade to see EV calculations"
                            >
                              <Typography variant={typographyVariant} style={{ color: gameLogs[0].away_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[0].away_diff * 100 >= 2 ? 'bold' : 'normal' }}>
                                {gameLogs[0].away_diff !== '--' && !isNaN(gameLogs[0].away_diff) ? `${Math.round(gameLogs[0].away_diff * 1000) / 10}%` : '--'}
                              </Typography>
                              <Typography variant={typographyVariant} style={{ color: gameLogs[0].home_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[0].home_diff * 100 >= 2 ? 'bold' : 'normal' }}>
                                {gameLogs[0].home_diff !== '--' && !isNaN(gameLogs[0].home_diff) ? `${Math.round(gameLogs[0].home_diff * 1000) / 10}%` : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="EV calculations for members available ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>{gameLogs[1].away_team_price > 0 ? '+' : ''}{gameLogs[1].away_team_price}</Typography>
                          <Typography variant={typographyVariant}>{gameLogs[1].home_team_price > 0 ? '+' : ''}{gameLogs[1].home_team_price}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn
                              feature="view_ev_data"
                              tooltipText="Upgrade to see EV calculations"
                            >
                              <Typography variant={typographyVariant} style={{ color: gameLogs[1].away_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[1].away_diff * 100 >= 2 ? 'bold' : 'normal' }}>
                                {gameLogs[1].away_diff !== '--' && !isNaN(gameLogs[1].away_diff) ? `${Math.round(gameLogs[1].away_diff * 1000) / 10}%` : '--'}
                              </Typography>
                              <Typography variant={typographyVariant} style={{ color: gameLogs[1].home_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[1].home_diff * 100 >= 2 ? 'bold' : 'normal' }}>
                                {gameLogs[1].home_diff !== '--' && !isNaN(gameLogs[1].home_diff) ? `${Math.round(gameLogs[1].home_diff * 1000) / 10}%` : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="EV calculations for members available ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}> {renderFormattedDate(gameLogs[0].commence_time)}</Typography>
                          <Typography variant={typographyVariant}> {renderFormattedTime(gameLogs[0].commence_time)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            
            {/* Related Content Section - Restored Card Version */}
            <Box sx={{ mt: 5, mb: 3, pt: 3, borderTop: `1px solid ${theme.palette.grey[200]}` }}>
              <Typography variant="h5" component="h3" sx={{ mb: 3, fontWeight: 700 }}>
                Continue Your NHL Betting Journey
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsHockeyIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NHL Betting Guide
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Comprehensive strategies, tips, and insights for betting on NHL hockey.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/nhl" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Guide
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsHockeyIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NHL Puck Lines
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Find value in NHL puck line markets with our odds comparison and analysis.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/nhl/spread" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Puck Lines
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsHockeyIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NHL Totals
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Compare NHL over/under odds and find the best totals betting opportunities.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/nhl/total" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Totals
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Main>
      </Box>
    </>
  );
};

// Apply the withSeasonCheck HOC to the NHLPage component
export default withSeasonCheck(NHLPage, 'NHL');