import React, { createContext, useContext } from 'react';
import { useUser } from './UserContext';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const { status, isLoading, subscription } = useUser();

  const value = {
    isLoading,
    isPremium: status === 'PREMIUM',
    data: subscription,
    error: null
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext); 