import React, { createContext, useContext } from 'react';
import { ACTIVE_SEASONS } from './seasons';

// Create the context
const SeasonContext = createContext();

// Provider component that wraps your app and makes season data available
export const SeasonProvider = ({ children }) => {
  // Function to check if a specific sport is active
  const isSeasonActive = (sport) => ACTIVE_SEASONS[sport] === true;
  
  // Get an array of all active sports
  const activeSports = Object.keys(ACTIVE_SEASONS).filter(sport => ACTIVE_SEASONS[sport]);
  
  // Context value with season utilities
  const value = {
    activeSeasons: ACTIVE_SEASONS,
    isSeasonActive,
    activeSports
  };
  
  return (
    <SeasonContext.Provider value={value}>
      {children}
    </SeasonContext.Provider>
  );
};

// Custom hook to use the season context
export const useSeason = () => {
  const context = useContext(SeasonContext);
  if (context === undefined) {
    throw new Error('useSeason must be used within a SeasonProvider');
  }
  return context;
}; 