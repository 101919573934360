import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuth0 } from '@auth0/auth0-react';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const features = [
  {
    title: 'NFL Analytics',
    id: 1,
  },
  {
    title: 'NBA Analytics',
    id: 2,
  },
  {
    title: 'MLB Analytics',
    id: 3,
  },
  {
    title: 'NCAA Sports',
    id: 4,
  },
  {
    title: 'Advanced Stats',
    id: 5,
  },
  {
    title: 'Real-time Updates',
    id: 6,
  },
  {
    title: 'Premium Support',
    id: 7,
  },
];

const pricing = [
  {
    title: 'Free',
    price: {
      monthly: 0,
      annual: 0,
    },
    features: [1],
    isHighlighted: false,
    btnText: 'Get Started',
  },
  {
    title: 'Premium',
    price: {
      annual: 290,
      monthly: 25,
    },
    features: [1, 2, 3, 4, 5, 6, 7],
    isHighlighted: true,
    btnText: 'Upgrade Now',
  },
];

const PricingCompareTable = () => {
  const theme = useTheme();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const getButtonText = (plan) => {
    if (!isAuthenticated) {
      return plan.title === 'Premium' ? 'Join Today' : 'Get Started';
    }
    return plan.btnText;
  };

  const handlePricingClick = async (plan) => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        appState: { 
          returnTo: plan === 'Premium' ? '/upgrade' : '/',
          plan: plan 
        }
      });
    } else {
      if (plan === 'Premium') {
        navigate('/upgrade');
      } else {
        navigate('/');
      }
    }
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography 
          fontWeight={700} 
          variant={'h4'} 
          align={'center'}
          sx={{ mb: 4 }}
        >
          {isAuthenticated ? 'Compare Your Current Plan' : 'Plan Comparison'}
        </Typography>
      </Box>
      <Box>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="caption table" sx={{ minWidth: 600 }}>
            <caption>
              {isAuthenticated 
                ? 'See what additional features you\'ll get by upgrading to Premium'
                : 'Compare the plans and choose the one which works for you the best.'
              }
            </caption>
            <TableHead>
              <TableRow>
                <TableCell>Features</TableCell>
                {pricing.map((item, i) => (
                  <TableCell align="center" key={i}>
                    <Typography
                      sx={{ 
                        textTransform: 'uppercase', 
                        fontWeight: 600,
                        color: item.isHighlighted ? theme.palette.primary.main : 'text.primary'
                      }}
                    >
                      {item.title}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature.id}>
                  <TableCell component="th" scope="row">
                    {feature.title}
                  </TableCell>
                  {pricing.map((plan, i) => (
                    <TableCell 
                      align="center" 
                      key={i}
                      sx={{
                        backgroundColor: plan.isHighlighted 
                          ? alpha(theme.palette.primary.main, 0.05)
                          : 'transparent'
                      }}
                    >
                      <Box display={'flex'} justifyContent={'center'}>
                        {plan.features.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                            sx={{
                              bgcolor: plan.isHighlighted 
                                ? theme.palette.primary.main 
                                : theme.palette.secondary.main
                            }}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                <TableCell />
                {pricing.map((item, i) => (
                  <TableCell 
                    align="center" 
                    key={i}
                    sx={{
                      backgroundColor: item.isHighlighted 
                        ? alpha(theme.palette.primary.main, 0.05)
                        : 'transparent'
                    }}
                  >
                    <Button
                      size={'large'}
                      variant={item.isHighlighted ? 'contained' : 'outlined'}
                      onClick={() => handlePricingClick(item.title)}
                    >
                      {getButtonText(item)}
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PricingCompareTable;
