import {
  Home,
  EmailConfirmation,
  Login,
  Pricing,
  Upgrade,
  UpgradeSuccess,
} from 'pages';
import Sitemap from '../components/Sitemap';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <Home {...params} />,
    public: true
  },
  {
    path: '/sitemap.xml',
    renderer: () => <Sitemap />
  },
  {
    path: '/confirm-email',
    renderer: (params = {}) => <EmailConfirmation {...params} />,
    public: true
  },
  {
    path: '/pricing',
    renderer: () => <Pricing />,
    public: true
  },
  {
    path: '/login',
    renderer: () => <Login />,
    public: true
  },
  {
    path: '/upgrade',
    renderer: () => <Upgrade />,
    public: true
  },
  {
    path: '/upgrade/success',
    renderer: () => <UpgradeSuccess />,
    public: true
  },
];

export default routes;