import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SEO from 'components/SEO';
import seoConfig from '../../config/seo.json';
import LegalPage from 'components/LegalPage/LegalPage';

const ResponsibleGambling = () => {
  return (
    <>
      <SEO {...seoConfig.responsibleGambling} />
      <LegalPage title="Responsible Gambling">
        <Typography variant="h6" gutterBottom>
          Our Commitment
        </Typography>
        <Typography paragraph>
          +EV Bets is committed to promoting responsible gambling practices. While we provide betting analytics, 
          we encourage all users to gamble responsibly and within their means.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Signs of Problem Gambling
        </Typography>
        <Typography component="div" paragraph>
          <Box component="ul" sx={{ pl: 4 }}>
            <li>Betting more than you can afford to lose</li>
            <li>Chasing losses</li>
            <li>Gambling interfering with work or relationships</li>
            <li>Borrowing money to gamble</li>
          </Box>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Getting Help
        </Typography>
        <Typography paragraph>
          If you or someone you know has a gambling problem, help is available:
        </Typography>
        <Box component="ul" sx={{ pl: 4 }}>
          <li>
            <Link href="tel:1-800-522-4700">
              National Problem Gambling Helpline: 1-800-522-4700
            </Link>
          </li>
          <li>
            <Link href="https://www.gamblersanonymous.org" target="_blank" rel="noopener noreferrer">
              Gamblers Anonymous
            </Link>
          </li>
        </Box>

        {/* Add more sections as needed */}
      </LegalPage>
    </>
  );
};

export default ResponsibleGambling; 