import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0, getAccessTokenSilently } from '@auth0/auth0-react';
import { useApi } from 'services/api';
import CircularProgress from '@mui/material/CircularProgress';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import Container from 'components/Container';

const mock = [
  {
    title: 'Free',
    subtitle: 'Basic sports betting analytics',
    price: { monthly: '$0', annual: '$0' },
    savings: { annual: '$0' },
    features: [
      'NFL Analytics',
      'Basic Stats',
      'Game Predictions'
    ],
    isHighlighted: false,
    btnText: 'Get Started'
  },
  {
    title: 'Premium',
    subtitle: 'Full access to all sports analytics',
    price: { monthly: '$25', annual: '$240' },
    savings: { annual: '$60' },
    features: [
      'All Free Features',
      'NBA Analytics',
      'MLB Analytics',
      'Advanced Stats',
      'Real-time Updates'
    ],
    isHighlighted: true,
    btnText: 'Upgrade Now'
  },
];

const iconSx = { 
  fontSize: 40, 
  color: 'primary.main',
  p: 1,
  backgroundColor: 'primary.lighter',  // Use a lighter shade
  borderRadius: '50%',
  opacity: 0.9,
  boxShadow: 1  // Add subtle shadow
};

const Main = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const location = useLocation();
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();
  const message = location.state?.message;
  const sport = location.state?.sport;
  const { callApi } = useApi();
  const [pricingOption, setPricingOption] = useState('annual');
  const [userSubscription, setUserSubscription] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption);
  };

  const handlePricingClick = async (plan) => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        screen_hint: 'signup',
        appState: { 
          returnTo: plan === 'Premium' ? '/upgrade' : '/',
          plan: plan 
        }
      });
    } else {
      if (plan === 'Premium') {
        handleSubscribe(plan, pricingOption === 'annual');
      } else {
        navigate('/');
      }
    }
  };

  const handleSubscribe = async (plan, isAnnual) => {
    try {
      console.log('Starting checkout...', { plan, isAnnual, userId: user?.sub });
      
      const response = await callApi('create-checkout-session', {
        method: 'POST',
        body: JSON.stringify({
          plan,
          isAnnual,
          userId: user.sub,
          returnUrl: `${window.location.origin}/account?upgrade=success`
        })
      });

      // Check if user is already subscribed
      if (response.status === 'error' && response.code === 'already_subscribed') {
        const shouldManage = window.confirm(
          `${response.message}\n\nWould you like to manage your subscription?`
        );
        if (shouldManage && response.action?.url) {
          window.location.href = response.action.url;
        }
        return;
      }

      // Normal checkout flow
      if (response.sessionUrl) {
        console.log('Got session URL:', response.sessionUrl);
        window.location.href = response.sessionUrl;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Subscription error:', error);
      const message = error.message === 'Failed to fetch' 
        ? 'Unable to connect to the server. Please try again.'
        : error.message || 'An unexpected error occurred';
      alert(`Failed to start checkout: ${message}`);
    }
  };

  const testAuth = async () => {
    try {
      const data = await callApi('test-auth');
      console.log('Test response:', data);
    } catch (error) {
      console.error('Test auth error:', error);
    }
  };

  // Modify the subscription check
  const checkSubscriptionStatus = async () => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }
    
    setLoading(true);  // Only set loading true when we're actually checking
    try {
      const data = await callApi('subscription-details');
      setUserSubscription(data);
    } catch (error) {
      console.error('Error checking subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  // Check subscription status when user is authenticated
  useEffect(() => {
    checkSubscriptionStatus();
  }, [isAuthenticated]);

  // Modify the button text/action based on subscription status
  const getPlanButton = (plan) => {
    if (loading) {
      return {
        text: '...',
        action: () => {},
        disabled: true
      };
    }

    // For logged out users
    if (!isAuthenticated) {
      if (plan.title === 'Premium') {
        return {
          text: 'Join Today',
          action: () => handlePricingClick(plan.title),
          disabled: false
        };
      }
      return {
        text: 'Get Started',
        action: () => handlePricingClick(plan.title),
        disabled: false
      };
    }

    // For logged in users
    if (plan.title === 'Premium') {
      if (userSubscription) {
        return {
          text: 'Manage Subscription',
          action: () => navigate('/account'),
          disabled: false
        };
      }
      return {
        text: 'Upgrade Now',
        action: () => handlePricingClick(plan.title),
        disabled: false
      };
    }

    return {
      text: plan.btnText,
      action: () => handlePricingClick(plan.title),
      disabled: false
    };
  };

  // Highlight features based on sport
  const highlightFeature = (feature) => {
    if (!sport) return {};
    
    const sportFeatures = {
      'NBA': ['NBA Analytics', 'Real-time Updates'],
      'MENSCBB': ['NCAA Sports', 'Advanced Stats']
    };
    
    return sportFeatures[sport]?.includes(feature) 
      ? { 
        backgroundColor: theme.palette.primary.light,
        padding: '4px 8px',
        borderRadius: '4px',
        color: 'white'
      } 
      : {};
  };

  // Filter plans based on subscription status
  const getVisiblePlans = () => {
    if (loading) return mock; // Show all while loading
    if (userSubscription) {
      // Only show Premium plan for subscribed users
      return mock.filter(plan => plan.title === 'Premium');
    }
    return mock; // Show all plans for non-subscribed users
  };

  const renderToggler = () => (
    <Box 
      display={'flex'} 
      justifyContent={'center'} 
      marginBottom={-8}
      sx={{
        p: 1,
        borderRadius: 1,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 1
      }}
    >
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="monthly"
          size={isMd ? 'large' : 'small'}
          sx={{
            backgroundColor:
              pricingOption === 'monthly'
                ? `${theme.palette.primary.light} !important`
                : 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === 'monthly' ? 'common.white' : 'text.primary',
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="annual"
          size={isMd ? 'large' : 'small'}
          sx={{
            backgroundColor:
              pricingOption === 'annual'
                ? `${theme.palette.primary.light} !important`
                : 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
            ml: -1  // Remove gap between buttons
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === 'annual' ? 'common.white' : 'text.primary',
            }}
          >
            Annual • Save 20%
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          marginTop: -13,
          // paddingTop: 13,
        }}
      >
        <Container position={'relative'} zIndex={3}>
          
          <Box>
            <Box marginBottom={4}>
              {message && (
                <Typography
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  sx={{ mb: 2 }}
                >
                  {message}
                </Typography>
              )}
              <Typography
                variant="h4"
                gutterBottom
                align={'center'}
                sx={{
                  fontWeight: 900,
                }}
              >
                {userSubscription 
                  ? 'Manage Your Premium Subscription'
                  : 'Start finding your +EV Bets today'}
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.primary"
                align={'center'}
              >
                {userSubscription 
                  ? 'Thank you for being a premium member. Manage your subscription settings below.'
                  : 'Powered by data science to identify the best betting opportunities for you'}
              </Typography>
            </Box>
            {!userSubscription && renderToggler()}
          </Box>
        </Container>
      </Box>
      <Container>
        <Grid 
          container 
          spacing={4} 
          justifyContent="center"
          sx={{ 
            maxWidth: 'md',
            margin: '0 auto',
            width: '100%'
          }}
        >
          {getVisiblePlans().map((item, i) => (
            <Grid 
              item 
              xs={12} 
              md={userSubscription ? 6 : 6} // Adjust width for single card
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
              key={i}
            >
              <Box
                component={Card}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                variant={'outlined'}
                sx={(theme) => ({
                  borderColor: item.isHighlighted ? theme.palette.primary.main : undefined,
                  boxShadow: item.isHighlighted ? theme.shadows[4] : undefined,
                  width: '100%',  // Full width within container
                  maxWidth: {     // Limit max width
                    xs: '100%',   // Full width on mobile
                    sm: '450px'   // Limited width on larger screens
                  }
                })}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={'h4'} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={'text.secondary'}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    marginBottom={2}
                  >
                    <Typography variant={'h3'} fontWeight={700}>
                      {pricingOption === 'annual'
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    <Typography
                      variant={'subtitle1'}
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      {pricingOption === 'annual' ? '/y' : '/mo'}
                    </Typography>
                    {pricingOption === 'annual' && item.savings.annual !== '$0' && (
                      <Box 
                        sx={{ 
                          ml: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          variant={'subtitle1'}
                          color={'success.main'}
                          fontWeight={700}
                        >
                          Save {item.savings.annual}
                        </Typography>
                        <Typography
                          variant={'caption'}
                          color={'text.secondary'}
                        >
                          Just ${Math.round(parseInt(item.price.annual.slice(1)) / 12)}/mo
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={'auto'}
                          padding={0}
                        >
                          <Box
                            component={ListItemAvatar}
                            minWidth={'auto !important'}
                            marginRight={2}
                          >
                            <Box
                              component={Avatar}
                              bgcolor={theme.palette.primary.main}
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText 
                            primary={feature} 
                            sx={highlightFeature(feature)}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button 
                      size={'large'} 
                      variant={item.isHighlighted ? 'contained' : 'outlined'}
                      onClick={getPlanButton(item).action}
                      disabled={getPlanButton(item).disabled}
                    >
                      {getPlanButton(item).text}
                    </Button>
                  )}
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Main;
