import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'assets/logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import { ACTIVE_SEASONS } from '../../../../config/seasons';
import { useUser, USER_STATES } from '../../../../contexts/UserContext';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { NavItem } from './components';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { loginWithRedirect: login } = useAuth0();
  const { isAuthenticated, status } = useUser();

  // Only show Sign In if not authenticated AND browsing
  const showSignIn = !isAuthenticated && status === 'browsing';

  const {
    nfl: nflPages = [],    // Provide default empty arrays
    menscbb: menscbbPages = [],
    nba: nbaPages = [],
    mlb: mlbPages = [],
    nhl: nhlPages = [],
    ncaaf: ncaafPages = [],
  } = pages;

  // console.log('Pages prop:', pages);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="+EV Bets"
        width={{ xs: 150, md: 200 }}
      >
        <Box
          component={'img'}
          src={Logo}
          height={1}
          width={1}
        />
      </Box>
      <Box 
        sx={{ 
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          flex: 1,
          justifyContent: 'flex-end'  // Changed to flex-end
        }}
      >
        <Box display="flex" alignItems="center">
          {ACTIVE_SEASONS.NFL && (
            <Box>
              <NavItem
                title={'NFL'}
                id={'nfl-pages'}
                items={nflPages}
                colorInvert={colorInvert}
              />
            </Box>
          )}
          {ACTIVE_SEASONS.NBA && (
            <Box marginLeft={4}>
              <NavItem
                title={'NBA'}
                id={'nba-pages'}
                items={nbaPages}
                colorInvert={colorInvert}
              />
            </Box>
          )}
          {ACTIVE_SEASONS.MENSCBB && (
            <Box marginLeft={4}>
              <NavItem
                title={'NCAA Mens Basketball'}
                id={'mens-cbb-pages'}
                items={menscbbPages}
                colorInvert={colorInvert}
              />
            </Box>
          )}
          {ACTIVE_SEASONS.MLB && (
            <Box marginLeft={4}>
              <NavItem
                title={'MLB'}
                id={'mlb-pages'}
                items={mlbPages}
                colorInvert={colorInvert}
              />
            </Box>
          )}
          {ACTIVE_SEASONS.NHL && (
            <Box marginLeft={4}>
              <NavItem
                title={'NHL'}
                id={'nhl-pages'}
                items={nhlPages}
                colorInvert={colorInvert}
              />
            </Box>
          )}
          {ACTIVE_SEASONS.NCAAF && (
            <Box marginLeft={4}>
              <NavItem
                title={'NCAA Football'}
                id={'ncaaf-pages'}
                items={ncaafPages}
                colorInvert={colorInvert}
              />
            </Box>
          )}
        </Box>
        {showSignIn && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => login()}
            sx={{
              ml: 4,
              borderRadius: 2,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Sign In
          </Button>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
