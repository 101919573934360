import NFLmoneyline from './NFLmoneyline/NFLmoneyline';
import NFLspread from './NFLspread/NFLspread';
import NFLtotal from './NFLtotal/NFLtotal';

const nflRoutes = [
  {
    path: '/nfl/moneyline',
    label: 'Moneylines',
    renderer: (params = {}) => <NFLmoneyline {...params} />
  },
  {
    path: '/nfl/spread',
    label: 'Spreads',
    renderer: (params = {}) => <NFLspread {...params} />
  },
  {
    path: '/nfl/total',
    label: 'Totals',
    renderer: (params = {}) => <NFLtotal {...params} />
  }
];

export default nflRoutes;