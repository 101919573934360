import { Tabs, Tab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { ACTIVE_SEASONS } from '../config/seasons';

// Custom styles using styled from @mui/system
const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2), // Add space between sport and market navigation
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  minWidth: 100,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    opacity: 1,
  },
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(14),
    minWidth: 80,
  },
}));

// Sport configuration mapping
const sportConfig = {
  nba: { label: 'NBA', path: '/nba' },
  nfl: { label: 'NFL', path: '/nfl' },
  mlb: { label: 'MLB', path: '/mlb' },
  nhl: { label: 'NHL', path: '/nhl' },
  ncaaf: { label: 'NCAAF', path: '/ncaaf' },
  menscbb: { label: 'NCAAM', path: '/mens-cbb' },
};

const SportNavTabs = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/').filter(Boolean);
  
  // Extract current sport and market
  const currentSport = pathParts[0];
  const currentMarket = pathParts[1];

  // Generate routes for active sports
  const routes = Object.entries(sportConfig)
    .filter(([sport]) => ACTIVE_SEASONS[sport.toUpperCase()])
    .map(([sport, config]) => ({
      label: config.label,
      path: `${config.path}/${currentMarket}`,
    }));

  return (
    <StyledTabs 
      value={currentSport} 
      centered
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: 'primary.main',
          height: 3,
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        },
        '& .MuiTabs-flexContainer': {
          gap: 2,  // Add some spacing between tabs
        },
        '& .Mui-selected': {
          color: 'primary.main',
        },
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      {routes.map((route, index) => (
        <StyledTab
          key={index}
          label={route.label}
          value={route.path.split('/')[1]} // Use sport code as value
          component={Link}
          to={route.path}
          aria-label={route.label}
        />
      ))}
    </StyledTabs>
  );
};

export default SportNavTabs; 