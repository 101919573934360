import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import ErrorBoundary from './components/ErrorBoundary';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { UserProvider } from './contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { SeasonProvider } from './config/seasonContext';
import ScrollToTop from './components/ScrollToTop';

const Auth0ProviderWithRedirect = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    console.log('Auth0 redirect callback', { appState });
    navigate(appState?.returnTo || '/dashboard', { replace: true });
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0-return`,
        audience: 'https://auth.plusevbets.com',
        scope: 'openid profile email offline_access'
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const App = () => {
  // console.log('Auth0 Config:', {
  //   domain: process.env.REACT_APP_AUTH0_DOMAIN,
  //   clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  //   audience: process.env.REACT_APP_AUTH0_AUDIENCE
  // });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Auth0ProviderWithRedirect>
        <UserProvider>
          <HelmetProvider>
            <SubscriptionProvider>
              <SeasonProvider>
                <Page>
                  <ErrorBoundary>
                    <Routes />
                  </ErrorBoundary>
                </Page>
              </SeasonProvider>
            </SubscriptionProvider>
          </HelmetProvider>
        </UserProvider>
      </Auth0ProviderWithRedirect>
    </BrowserRouter>
  );
};

export default App;
