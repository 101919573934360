import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Sidebar = ({ pages, open, variant, onClose }) => {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  
  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          pb: '120px'  // We can probably reduce this since we removed a button
        }}
      >
        {/* Main navigation */}
        <SidebarNav pages={pages} />

        {/* Fixed Bottom Auth Bar */}
        <Box sx={{ 
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          maxWidth: 280,
          bgcolor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5
        }}>
          {isAuthenticated ? (
            <>
              <Button
                component={RouterLink}
                to="/dashboard"
                variant="text"
                fullWidth
                sx={{ 
                  justifyContent: 'flex-start',
                  px: 2
                }}
              >
                Dashboard
              </Button>
              <Button
                component={RouterLink}
                to="/account"
                variant="text"
                fullWidth
                sx={{ 
                  justifyContent: 'flex-start',
                  px: 2
                }}
              >
                Account
              </Button>
              <Button
                onClick={() => logout()}
                variant="text"
                fullWidth
                sx={{ 
                  justifyContent: 'flex-start',
                  px: 2
                }}
              >
                Logout
              </Button>
            </>
          ) : (
            <Button
              onClick={() => loginWithRedirect()}
              variant="contained"
              fullWidth
            >
              Sign In
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired,
};

export default Sidebar;
