import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Main from 'layouts/Main';
import Typography from '@mui/material/Typography';
import { Paper, Button, Divider, Grid } from '@mui/material';
import SEO from 'components/SEO';
import { useSeason } from '../config/seasonContext';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SendIcon from '@mui/icons-material/Send';

const OffSeason = ({ sport }) => {
  const { activeSports } = useSeason();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const sportNames = {
    NFL: 'NFL Football',
    NBA: 'NBA Basketball',
    NHL: 'NHL Hockey',
    MLB: 'MLB Baseball',
    NCAAF: 'College Football',
    MENSCBB: 'Men\'s College Basketball'
  };

  const sportIcons = {
    NFL: <SportsFootballIcon fontSize="large" />,
    NBA: <SportsBasketballIcon fontSize="large" />,
    NHL: <SportsHockeyIcon fontSize="large" />,
    MLB: <SportsBaseballIcon fontSize="large" />,
    NCAAF: <SportsFootballIcon fontSize="large" />,
    MENSCBB: <SportsBasketballIcon fontSize="large" />
  };

  const sportPaths = {
    NFL: 'nfl',
    NBA: 'nba',
    NHL: 'nhl',
    MLB: 'mlb',
    NCAAF: 'ncaaf',
    MENSCBB: 'mens-cbb'
  };

  const sportName = sportNames[sport] || sport;
  const sportIcon = sportIcons[sport] || <SportsTennisIcon fontSize="large" />;
  
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = (event) => {
    event.preventDefault();
    setSuccessMessage('');
    
    if (email === '') {
      setError('Please enter an email address');
      return;
    } 
    
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    setError('');
    
    fetch('/api/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setSuccessMessage(data.message || 'Thanks for subscribing! Check your email for updates.');
          setEmail('');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('An error occurred while subscribing. Please try again later.');
      });
  };

  return (
    <>
      <SEO 
        title={`${sportName} Off-Season | Coming Soon`}
        description={`${sportName} is currently in the off-season. Our predictions and odds comparison will return next seaason.`}
      />
      <Box sx={{ overflowX: 'hidden' }}>
        <Main bgcolor={'background.paper'}>
          <Container>
            <Paper sx={{ p: 4, textAlign: 'center', my: 4 }}>
              {/* Sport Icon and Off-Season Message */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                {sportIcon}
              </Box>
              <Typography variant="h4" component="h1" gutterBottom>
                {sportName} is currently in off-season
              </Typography>
              <Typography variant="body1" paragraph>
                Our {sportName} predictions and odds comparison will return next season.
              </Typography>
              
              {/* Resources Section - Directly below the off-season message */}
              <Box sx={{ mt: 2, mb: 3 }}>
                <Typography variant="body2" paragraph>
                  Stay connected with {sportName} during the off-season:
                </Typography>
                
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="a"
                      href="/blog"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read Our Blog
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="a"
                      href="/about"
                    >
                      About +EV Bets
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              
              <Divider sx={{ my: 3 }} />
              
              {/* Newsletter Signup Section */}
              <Box sx={{ mt: 3, mb: 4 }}>
                {/* <Typography variant="h5" gutterBottom>
                  Get notified when {sportName} returns
                </Typography> */}
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Sign up for our newsletter to receive alerts when the {sportName} season begins.
                </Typography>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                  <Box component="form" onSubmit={handleSubscribe} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', maxWidth: '500px' }}>
                    <Box sx={{ 
                      flex: 1, 
                      minWidth: '250px',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      <input
                        type="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          padding: '10px 14px',
                          borderRadius: '4px',
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                          width: '100%',
                          fontSize: '16px',
                          height: '40px',
                          boxSizing: 'border-box'
                        }}
                      />
                      {error && (
                        <Typography color="error" variant="caption" sx={{ mt: 1, textAlign: 'left' }}>
                          {error}
                        </Typography>
                      )}
                    </Box>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      endIcon={<SendIcon />}
                      type="submit"
                      sx={{
                        height: '40px',
                        ml: { xs: 0, sm: 1 },
                        mt: { xs: 1, sm: 0 },
                        minWidth: { xs: '100%', sm: 'auto' }
                      }}
                    >
                      Subscribe
                    </Button>
                  </Box>
                  {successMessage && (
                    <Typography 
                      color="primary" 
                      sx={{ 
                        mt: 2, 
                        fontWeight: 'bold', 
                        color: 'green',
                        width: '100%'
                      }}
                    >
                      {successMessage}
                    </Typography>
                  )}
                </Box>
              </Box>
              
              <Divider sx={{ my: 3 }} />
              
              {/* Active Sports Section - At the bottom */}
              {activeSports.length > 0 && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Check out our active sports
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 3 }}>
                    While you wait for {sportName} to return, explore our predictions for these sports:
                  </Typography>
                  
                  <Grid container spacing={2} justifyContent="center">
                    {activeSports.map(activeSport => (
                      <Grid item key={activeSport}>
                        <Button
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={`/${sportPaths[activeSport]}/moneyline`}
                          startIcon={sportIcons[activeSport]}
                          sx={{ minWidth: '180px' }}
                        >
                          {sportNames[activeSport]}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Paper>
          </Container>
        </Main>
      </Box>
    </>
  );
};

export default OffSeason; 