import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';

const UpgradeSuccess = () => {
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    // Verify the payment status with your backend
    const verifyPayment = async () => {
      try {
        const response = await fetch('/api/verify-upgrade');
        if (!response.ok) {
          throw new Error('Failed to verify upgrade');
        }
        setVerifying(false);
      } catch (error) {
        console.error('Verification failed:', error);
        navigate('/pricing');
      }
    };

    verifyPayment();
  }, [navigate]);

  if (verifying) {
    return (
      <Container>
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          minHeight="60vh"
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>
            Verifying your upgrade...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        minHeight="60vh"
      >
        <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
        <Typography variant="h4" gutterBottom>
          Welcome to Premium!
        </Typography>
        <Typography color="text.secondary" paragraph align="center">
          Your account has been upgraded successfully. You now have access to all premium features.
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate('/')}
          sx={{ mt: 2 }}
        >
          Start Exploring
        </Button>
      </Box>
    </Container>
  );
};

export default UpgradeSuccess; 