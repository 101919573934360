import React from 'react';
import { useUser } from '../contexts/UserContext';
import { Tooltip, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { CallToAction } from './CallToAction/index';
import { PROTECTED_FEATURES } from '../config/protectedContent';

export const ProtectedColumn = ({ feature, children }) => {
  const { hasFeatureAccess, status } = useUser();
  const hasAccess = hasFeatureAccess(feature);
  const tooltipText = PROTECTED_FEATURES[feature]?.tooltip;

  // console.log('ProtectedColumn state check:', {
  //   feature,
  //   currentStatus: status,
  //   hasAccess
  // });

  // Add debug log
  // console.log('ProtectedColumn check:', {
  //   feature,
  //   hasAccess,
  //   tooltipText
  // });

  if (!hasAccess) {
    return (
      <Tooltip 
        title={
          <Box 
            sx={{ 
              p: 0.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box sx={{ mb: 1, textAlign: 'center' }}>{tooltipText}</Box>
            <CallToAction 
              type="primary"
              sx={{ 
                fontSize: '0.8rem',
                textTransform: 'none'
              }}
            />
          </Box>
        } 
        arrow
      >
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 1
          }}
        >
          <Box sx={{ opacity: 0.5 }}>
            --
          </Box>
          <LockIcon 
            sx={{ 
              fontSize: '2rem',
              color: 'text.secondary'
            }} 
          />
        </Box>
      </Tooltip>
    );
  }

  return children;
};