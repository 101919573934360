import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { renderFormattedTime, renderFormattedDate, groupLogs, formatTimeAgo, formatDateForTooltip } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Card, CardContent, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useApi } from 'services/api';
import { ProtectedColumn } from 'components/ProtectedColumn';
import SEO from 'components/SEO';
import seoConfig from '../../../config/seo.json';
import NavTabs from 'components/NavTabs';
import nhlRoutes from '../nhlRoutes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import { withSeasonCheck } from '../../../config/withSeasonCheck';
import { Link } from 'react-router-dom';
import SportNavTabs from '../../../components/SportNavTabs';

const NHLtotal = () => {
  const { callApi } = useApi();
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(null);
  const [hasModelData, setHasModelData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await callApi('/api/nhltotallog');
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);
        
        // Set the last update timestamp from the first record if available
        if (data.length > 0 && data[0].last_api_update) {
          setLastUpdateTimestamp(data[0].last_api_update);
        }

        // Check if model data is available
        const modelDataAvailable = data.some(log => log.model_prediction);
        setHasModelData(modelDataAvailable);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [callApi]);

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  const firstBookmaker = firstGroup && firstGroup[0] ? firstGroup[0].bookmaker : null;
  const secondBookmaker = firstGroup && firstGroup[1] ? firstGroup[1].bookmaker : null;
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };

  return (
    <>
      <SEO {...seoConfig.nhl.total} />
      <Box sx={{ overflowX: 'hidden' }}>
        <Main bgcolor={'background.paper'}>
          <Container>
            <div>
              <header>
                <h1>NHL Totals Odds</h1>
                <p>Compare totals (over/under) odds from multiple sportsbooks. While <a href="https://moneypuck.com/" target="_blank" rel="noopener noreferrer">MoneyPuck</a> provides excellent moneyline predictions, they don't currently offer totals predictions.</p>
                <p>Use this page to find the best available odds across sportsbooks for your NHL totals bets.</p>
              </header>
              <Typography variant="body2" component="p" sx={{ fontStyle: 'italic' }}>
                Lines updated {lastUpdateTimestamp ? formatDateForTooltip(lastUpdateTimestamp) : 'unknown time'}. Updates every 2-4 hours, more frequent closer to game time.
              </Typography>
              {!hasModelData && logs.length > 0 && (
                <p><i><AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} /> Model predictions normally available ~7-8am EST. Odds data is displayed below.</i></p>
              )}
            </div>
            <div>
              <SportNavTabs />
              <NavTabs routes={nhlRoutes} />
            </div>
            {logs.length === 0 ? (
              <div><b>No upcoming NHL games found. Please check back later.</b></div>
            ) : (
              <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
                <Table sx={{ minWidth: 400}} aria-label="nhl-total-table">
                  <TableHead sx={{ bgcolor: 'grey.200' }}>
                    <TableRow>
                      <TableCell style={{ verticalAlign: 'top' }}><strong>Game</strong></TableCell>
                      <TableCell {...headerStyling}><strong>{firstBookmaker}</strong></TableCell>
                      <TableCell {...headerStyling}><strong>{secondBookmaker}</strong></TableCell>
                      <TableCell {...headerStyling}><strong>Puck Drop</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupLogs(logs).map((gameLogs, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography variant={typographyVariant}>{gameLogs[0]?.away_team || gameLogs[0]?.the_away_team}</Typography>
                          <Typography variant={typographyVariant}>{gameLogs[0]?.home_team || gameLogs[0]?.the_home_team}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>o{gameLogs[0].totals_point} {gameLogs[0].over_price > 0 ? '+' : ''}{gameLogs[0].over_price}</Typography>
                          <Typography variant={typographyVariant}>u{gameLogs[0].totals_point} {gameLogs[0].under_price > 0 ? '+' : ''}{gameLogs[0].under_price}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>o{gameLogs[1].totals_point} {gameLogs[1].over_price > 0 ? '+' : ''}{gameLogs[1].over_price}</Typography>
                          <Typography variant={typographyVariant}>u{gameLogs[1].totals_point} {gameLogs[1].under_price > 0 ? '+' : ''}{gameLogs[1].under_price}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>{renderFormattedDate(gameLogs[0].commence_time)}</Typography>
                          <Typography variant={typographyVariant}>
                            {(() => {
                              const time = new Date(gameLogs[0].commence_time);
                              time.setHours(time.getHours() - 4);
                              return renderFormattedTime(time);
                            })()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            
            {/* Related Content Section - Restored Card Version */}
            <Box sx={{ mt: 5, mb: 3, pt: 3, borderTop: `1px solid ${theme.palette.grey[200]}` }}>
              <Typography variant="h5" component="h3" sx={{ mb: 3, fontWeight: 700 }}>
                Continue Your NHL Betting Journey
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsHockeyIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NHL Betting Guide
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Comprehensive strategies, tips, and insights for betting on NHL hockey.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/nhl" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Guide
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsHockeyIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NHL Moneylines
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Find value in NHL moneyline markets with our model-based predictions.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/nhl/moneyline" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Moneylines
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsHockeyIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NHL Puck Lines
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Find value in NHL puck line markets with our odds comparison and analysis.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/nhl/spread" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Puck Lines
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Main>
      </Box>
    </>
  );
};

// Apply the withSeasonCheck HOC to the NHLtotal component
export default withSeasonCheck(NHLtotal, 'NHL');