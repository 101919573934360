import NHLmoneyline from './NHLmoneyline/NHLmoneyline';
import NHLspread from './NHLspread/NHLspread';
import NHLtotal from './NHLtotal/NHLtotal';

const nhlRoutes = [
  {
    path: '/nhl/moneyline',
    label: 'Moneylines',
    renderer: (params = {}) => <NHLmoneyline {...params} />
  },
  {
    path: '/nhl/spread',
    label: 'Spreads',
    renderer: (params = {}) => <NHLspread {...params} />
  },
  {
    path: '/nhl/total',
    label: 'Totals',
    renderer: (params = {}) => <NHLtotal {...params} />
  }
];

export default nhlRoutes; 