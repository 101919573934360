import NCAAFmoneyline from './NCAAFmoneyline/NCAAFmoneyline';
import NCAAFspread from './NCAAFspread/NCAAFspread';
import NCAAFtotal from './NCAAFtotal/NCAAFtotal';

const ncaafRoutes = [
  {
    path: '/ncaaf/moneyline',
    label: 'Moneylines',
    renderer: (params = {}) => <NCAAFmoneyline {...params} />
  },
  {
    path: '/ncaaf/spread',
    label: 'Spreads',
    renderer: (params = {}) => <NCAAFspread {...params} />
  },
  {
    path: '/ncaaf/total',
    label: 'Totals',
    renderer: (params = {}) => <NCAAFtotal {...params} />
  }
];

export default ncaafRoutes;