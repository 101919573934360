// This file now exports a function that takes the active seasons from context
// instead of directly importing ACTIVE_SEASONS

const getPages = (activeSeasons) => {
  return {
    offerings: [],
    ...(activeSeasons.MLB && {
      mlb: [
        {
          title: 'Moneylines',
          href: '/mlb/moneyline',
        },
        {
          title: 'Spreads',
          href: '/mlb/spread',
        },
        {
          title: 'Totals',
          href: '/mlb/total',
        },
        {
          title: 'Props',
          href: '/mlb/props',
          target: '_blank',
        },
      ],
      mlbProps: [
        {
          title: 'SP Strikeouts',
          href: '/mlb/props',
        },
      ],
    }),
    ...(activeSeasons.NHL && {
      nhl: [
        {
          title: 'Moneylines',
          href: '/nhl/moneyline',
        },
        {
          title: 'Spreads',
          href: '/nhl/spread',
        },
        {
          title: 'Totals',
          href: '/nhl/total',
        },
      ],
    }),
    ...(activeSeasons.NFL && {
      nfl: [
        {
          title: 'Moneylines',
          href: '/nfl/moneyline',
        },
        {
          title: 'Spreads',
          href: '/nfl/spread',
        },
        {
          title: 'Totals',
          href: '/nfl/total',
        },
      ],
    }),
    ...(activeSeasons.NBA && {
      nba: [
        {
          title: 'Moneylines',
          href: '/nba/moneyline',
        },
        {
          title: 'Spreads',
          href: '/nba/spread',
        },
        {
          title: 'Totals',
          href: '/nba/total',
        },
      ],
    }),
    ...(activeSeasons.MENSCBB && {
      menscbb: [
        {
          title: 'Moneylines',
          href: '/mens-cbb/moneyline',
        },
        {
          title: 'Spreads',
          href: '/mens-cbb/spread',
        },
        {
          title: 'Totals',
          href: '/mens-cbb/total',
        },
      ],
    }),
    // Add NCAAF section here
    ...(activeSeasons.NCAAF && {
      ncaaf: [
        {
          title: 'Moneylines',
          href: '/ncaaf/moneyline',
        },
        {
          title: 'Spreads',
          href: '/ncaaf/spread',
        },
        {
          title: 'Totals',
          href: '/ncaaf/total',
        },
      ],
    }),
    pricing: {
      title: 'Pricing',
      href: '/pricing',
    },
  };
};

export default getPages;