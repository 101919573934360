import NBAmoneyline from './NBAmoneyline/NBAmoneyline';
import NBAspread from './NBAspread/NBAspread';
import NBAtotal from './NBAtotal/NBAtotal';

const nbaRoutes = [
  {
    path: '/nba/moneyline',
    label: 'Moneylines',
    renderer: (params = {}) => <NBAmoneyline {...params} />
  },
  {
    path: '/nba/spread',
    label: 'Spreads',
    renderer: (params = {}) => <NBAspread {...params} />
  },
  {
    path: '/nba/total',
    label: 'Totals',
    renderer: (params = {}) => <NBAtotal {...params} />
  }
];

export default nbaRoutes;