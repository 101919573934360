import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import HeroImage from 'assets/heroimage2s.png';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useUser, USER_STATES } from '../../../../contexts/UserContext';
import { CallToAction } from '../../../../components/CallToAction/index';
import { HERO_CONTENT } from '../../../../config/content';
import { Helmet } from 'react-helmet-async';
import seoConfig from '../../../../config/seo';

const Hero = () => {
  const theme = useTheme();
  const { status, isLoading, previousStatus } = useUser();
  
  // Use previous status during loading or transitions
  const displayStatus = isLoading ? previousStatus : status;
  const content = HERO_CONTENT[displayStatus] || HERO_CONTENT[USER_STATES.BROWSING];
  
  // console.log('Hero render:', { status, isLoading, hasContent: !!HERO_CONTENT[status] });

  const { title, description, keywords } = seoConfig.home;

  if (isLoading) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.alternate.main,
          pt: { xs: 6, md: 12 },
          pb: { xs: 8, md: 12 },
          opacity: isLoading ? 0.7 : 1,  // Subtle opacity during loading
          transition: 'opacity 0.3s ease-in-out'
        }}
      >
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="h2"
                  color="text.primary"
                  gutterBottom
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '2rem', md: '3rem' }
                  }}
                >
                  {content.headline}
                  <br />
                  <Typography
                    component="span"
                    variant="h2"
                    color="primary"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: '2rem', md: '3rem' }
                    }}
                  >
                    {content.subheadline}
                  </Typography>
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.secondary"
                  sx={{ mb: 4 }}
                >
                  {content.description}
                </Typography>
                <Box>
                  {content.benefits.map((benefit, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 1,
                      }}
                    >
                      <CheckCircleOutlineIcon sx={{ color: 'primary.main', mr: 1 }} />
                      <Typography color="text.primary" variant="subtitle1">
                        {benefit}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ mt: 4 }}>
                  <CallToAction 
                    type="primary"
                    sx={{
                      py: 2,
                      px: 4,
                      fontSize: '1.1rem'
                    }}
                  />
                  {content.showFreeTrial && (
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        mt: 1,
                        color: 'text.secondary'
                      }}
                    >
                      No credit card required
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid 
              item 
              xs={12} 
              md={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: { xs: 2, md: 0 }
              }}
            >
              <Card
                sx={{
                  p: { xs: 2, md: 4 },
                  borderRadius: 2,
                  boxShadow: 2,
                  transform: {
                    xs: 'none',
                    md: 'perspective(1500px) rotateY(-15deg)'
                  },
                  maxWidth: { xs: '80%', md: '90%' },
                  backgroundColor: 'background.paper',
                }}
              >
                <Box
                  component="img"
                  src={HeroImage}
                  alt="+EV Bets"
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    display: 'block',
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Hero;
