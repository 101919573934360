import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Divider, Button, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Paper, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import NewsletterWithCard from '../IndexPage/components/NewsletterWithCard/NewsletterWithCard';

// This component is NOT wrapped with withSeasonCheck so it's always visible
const NBAHomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Main>
      <Helmet>
        <title>NBA Betting Guide: Find Value Bets & Odds | +EV Bets</title>
        <meta name="description" content="Expert NBA betting analysis, strategies, and odds comparison. Find the best value bets for NBA games with our data-driven approach to basketball wagering." />
        <meta name="keywords" content="NBA betting, basketball betting strategy, NBA odds, NBA value bets, NBA betting guide, NBA betting tips" />
        <link rel="canonical" href="https://plusevbets.com/nba" />
        <meta property="og:title" content="NBA Betting Guide: Find Value Bets & Odds | +EV Bets" />
        <meta property="og:description" content="Expert NBA betting analysis, strategies, and odds comparison. Find the best value bets for NBA games with our data-driven approach to basketball wagering." />
        <meta property="og:url" content="https://plusevbets.com/nba" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsOrganization",
              "name": "National Basketball Association",
              "alternateName": "NBA",
              "url": "https://plusevbets.com/nba",
              "description": "NBA betting analysis and value bets"
            }
          `}
        </script>
      </Helmet>

      {/* Improved Hero Section */}
      <Box 
        sx={{
          background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 6, md: 10 },
          mb: { xs: 4, md: 6 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box>
                <Typography 
                  variant="h1" 
                  component="h1" 
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '3.5rem' }, 
                    fontWeight: 800, 
                    color: 'white',
                    mb: 2,
                    lineHeight: 1.2
                  }}
                >
                  NBA Betting Analysis & Value Finder
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h2" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }, 
                    fontWeight: 400, 
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 4,
                    maxWidth: '90%'
                  }}
                >
                  Data-driven insights to help you find positive expected value in NBA betting markets
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Button 
                    component={Link} 
                    to="/nba/moneyline" 
                    variant="contained" 
                    color="secondary" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NBA Moneylines
                  </Button>
                  <Button 
                    component={Link} 
                    to="/nba/spread" 
                    variant="contained" 
                    color="secondary" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NBA Spreads
                  </Button>
                  <Button 
                    component={Link} 
                    to="/nba/total" 
                    variant="contained" 
                    color="secondary" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NBA Totals
                  </Button>
                </Box>
              </Box>
            </Grid>
            {isMd && (
              <Grid item md={5}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box 
                    sx={{
                      width: 300,
                      height: 300,
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <SportsBasketballIcon 
                      sx={{ 
                        fontSize: 180, 
                        color: 'rgba(255, 255, 255, 0.8)'
                      }} 
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '15%',
            minHeight: 50
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        {/* Main Content Grid */}
        <Grid container spacing={4}>
          {/* Left Column - Main Content */}
          <Grid item xs={12} md={8}>
            {/* Introduction Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NBA Betting: The Ultimate Guide
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                The National Basketball Association (NBA) offers one of the most dynamic and high-scoring betting environments in sports. 
                With 82 regular season games per team, the NBA provides countless opportunities for bettors to find value throughout the season.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we analyze NBA betting markets to find positive expected value opportunities. Our data-driven approach 
                helps you identify when the odds are in your favor, giving you an edge over the sportsbooks.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                Whether you're betting on point spreads, moneylines, totals, or player props, our NBA betting tools provide the insights 
                you need to make informed decisions in this fast-paced, high-scoring sport.
              </Typography>
            </Paper>

            {/* Our Model Section - REVISED */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NBA Predictions & Analysis
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we provide NBA betting insights by leveraging high-quality prediction data from trusted external sources. 
                Rather than developing our own predictive model, we focus on analyzing and presenting the most reliable NBA predictions in an accessible format.
              </Typography>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Our Approach
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Curated Data Sources</Typography>}
                          secondary="We carefully select prediction data from established statistical models"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Market Comparison</Typography>}
                          secondary="We compare predictions against current betting markets"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Value Identification</Typography>}
                          secondary="We highlight where the market may have mispriced outcomes"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Clear Presentation</Typography>}
                          secondary="We present complex data in an easy-to-understand format"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Finding Value
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                      We help you identify value bets by comparing statistical predictions with sportsbook odds. A value bet occurs when:
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1, mb: 2 }}>
                      <Typography sx={{ fontFamily: 'monospace', mb: 1 }}>
                        Predicted Probability {'>'} Implied Probability from Odds
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This suggests the market has undervalued the true likelihood of an outcome
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      By focusing on these discrepancies, you can make more informed betting decisions based on statistical advantage rather than gut feeling.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                For detailed NBA predictions and value bets, visit our <Link to="/nba/moneyline" style={{ color: theme.palette.primary.main, fontWeight: 600 }}>NBA Moneyline</Link> page where we present the latest data and highlight potential value opportunities. Our goal is to provide you with the tools and information needed to make smarter betting decisions, even if you're not a statistical expert.
              </Typography>
            </Paper>

            {/* NBA Betting Strategy Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NBA Betting Strategies
              </Typography>
              
              <Accordion defaultExpanded sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Point Spread Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Point spreads are a popular way to bet on NBA games, especially when there are significant talent disparities between teams.
                  </Typography>
                  <Typography paragraph>
                    <strong>Key factors to consider when betting NBA spreads:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Rest advantage/disadvantage (back-to-backs, 3 games in 4 nights)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Travel distance and schedule congestion" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Pace of play and stylistic matchups" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Motivation factors (playoff positioning, tanking, revenge games)" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nba/spread" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NBA Spread Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Moneyline Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Moneyline betting in the NBA can be challenging due to the prevalence of heavy favorites, but there's still value to be found.
                  </Typography>
                  <Typography paragraph>
                    <strong>Effective NBA moneyline betting approaches:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Targeting home underdogs in specific situations" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Identifying schedule advantage spots" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Analyzing lineup changes and injury impacts" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Leveraging live betting during momentum swings" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nba/moneyline" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NBA Moneyline Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Totals (Over/Under) Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    NBA totals betting requires understanding team pace, efficiency, and defensive matchups. With high-scoring games, small factors can significantly impact totals.
                  </Typography>
                  <Typography paragraph>
                    <strong>Factors that influence NBA totals:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Pace of play (possessions per game)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Offensive and defensive efficiency ratings" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Three-point shooting volume and efficiency" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Rest situations and fatigue factors" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nba/total" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NBA Totals Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Historical Trends Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NBA Betting Trends & Data Analysis
              </Typography>
              <Typography paragraph sx={{ mb: 3 }}>
                Understanding historical NBA betting trends can provide valuable context for your wagering decisions. While past performance doesn't guarantee future results, certain patterns have shown consistency over time.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Road Favorites After a Loss
                      </Typography>
                      <Typography variant="body2">
                        Quality teams coming off a loss as road favorites have covered the spread at a 55.8% rate over the past five seasons, suggesting a strong bounce-back tendency.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Back-to-Back Unders
                      </Typography>
                      <Typography variant="body2">
                        Teams playing the second game of a back-to-back have gone under their team total 57.3% of the time, particularly when the first game went to overtime or was high-scoring.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Home Court Value
                      </Typography>
                      <Typography variant="body2">
                        Home court advantage in the NBA has declined from 3.2 points to approximately 2.0 points over the past decade, creating value opportunities when lines haven't adjusted accordingly.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Fourth Quarter Scoring
                      </Typography>
                      <Typography variant="body2">
                        NBA games average 25% more points in the fourth quarter compared to the first quarter, making live betting on over totals valuable when games start slowly.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Right Column - Sidebar */}
          <Grid item xs={12} md={4}>
            {/* Quick Links Card */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                NBA Betting Markets
              </Typography>
              <List sx={{ bgcolor: theme.palette.grey[50], borderRadius: 2, overflow: 'hidden' }}>
                <ListItem 
                  component={Link} 
                  to="/nba/moneyline" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NBA Moneylines</Typography>} 
                    secondary="Find value in picking straight-up winners" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/nba/spread" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NBA Point Spreads</Typography>} 
                    secondary="Analyze handicapped matchups and line value" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/nba/total" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NBA Totals</Typography>} 
                    secondary="Over/under predictions based on scoring models" 
                  />
                </ListItem>
              </List>
            </Paper>

            {/* NBA Betting FAQ */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                NBA Betting FAQ
              </Typography>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How important are injuries in NBA betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Injuries are extremely important in NBA betting, perhaps more than in any other sport. A single star player can impact a spread by 4-8 points. However, the market often overreacts to big-name injuries while undervaluing role player absences. Our analysis considers not just who is out, but how teams perform in specific lineup configurations.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What's the best approach for NBA live betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    NBA games feature significant scoring runs and momentum shifts, making them ideal for live betting. The best approach is to identify teams that start slowly but finish strong (or vice versa), and target games where the pace is different than expected. Look for live lines that overreact to short-term variance rather than fundamental matchup advantages.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How should I approach NBA player props?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    NBA player props offer some of the best value in sports betting. Focus on matchup-specific advantages, recent role changes, and minutes projections. Player tracking data like usage rate, rebounding percentage, and assist rate are more predictive than raw averages. Also consider pace of play, as faster games create more opportunities for counting stats.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How do I account for rest in NBA betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Rest advantage is a significant factor in NBA betting. Teams playing the second night of a back-to-back perform about 2 points worse on average. This effect is magnified when the team also traveled between games or when key players have high minutes loads. Teams with 3+ days of rest often show improved shooting efficiency, especially from three-point range.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
        
        {/* Newsletter CTA Section */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterWithCard />
        </Box>
      </Container>
    </Main>
  );
};

export default NBAHomePage; 