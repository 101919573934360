import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Logo from 'assets/logo.png';
import NavItem from './components/NavItem';
import NavLink from './components/NavLink';
import { ACTIVE_SEASONS } from '../../../../../../config/seasons';

const SidebarNav = ({ pages }) => {
  const {
    nfl: nflPages = [],
    menscbb: menscbbPages = [],
    nba: nbaPages = [],
    mlb: mlbPages = [],
    nhl: nhlPages = [],
    ncaaf: ncaafPages = [],
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="+EV Bets"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={Logo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        {ACTIVE_SEASONS.NFL && (
          <Box>
            <NavItem title={'NFL'} items={nflPages} />
          </Box>
        )}
        {ACTIVE_SEASONS.NBA && (
          <Box>
            <NavItem title={'NBA'} items={nbaPages} />
          </Box>
        )}
        {ACTIVE_SEASONS.MENSCBB && (
          <Box>
            <NavItem title={'NCAA Mens Basketball'} items={menscbbPages} />
          </Box>
        )}
        {ACTIVE_SEASONS.MLB && (
          <Box>
            <NavItem title={'MLB'} items={mlbPages} />
          </Box>
        )}
        {ACTIVE_SEASONS.NHL && (
          <Box>
            <NavItem title={'NHL'} items={nhlPages} />
          </Box>
        )}
        {ACTIVE_SEASONS.NCAAF && (
          <Box>
            <NavItem title={'NCAA Football'} items={ncaafPages} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
