import React from 'react';
import { useUser, USER_STATES } from '../../contexts/UserContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { CTA_CONTENT } from '../../config/content';

export const CallToAction = ({ type = 'primary', ...props }) => {
  const { status, isLoading, previousStatus } = useUser();
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  
  // console.log('CallToAction:', { 
  //   status, 
  //   type, 
  //   isLoading,
  //   content: CTA_CONTENT[status]?.[type] 
  // });
  
  // Use previous status during loading or transitions
  const displayStatus = isLoading ? previousStatus : status;
  const config = CTA_CONTENT[displayStatus]?.[type] || CTA_CONTENT[USER_STATES.BROWSING][type];

  const handleClick = async () => {
    if (isLoading) return; // Prevent actions during loading

    if (status === USER_STATES.BROWSING && type === 'primary') {
      await loginWithRedirect({
        appState: { returnTo: config.returnTo }
      });
      return;
    }

    navigate(config.action);
  };

  return (
    <Button
      variant={type === 'primary' ? 'contained' : 'outlined'}
      onClick={handleClick}
      disabled={isLoading}
      sx={{
        ...props.sx,
        opacity: isLoading ? 0.7 : 1,
        transition: 'opacity 0.3s ease-in-out'
      }}
    >
      {config.text}
    </Button>
  );
}; 