import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MainLayout from 'layouts/Main';
import Container from 'components/Container';
import SEO from 'components/SEO';
import PricingMain from './components/Main';
import {
  ValueProposition,
  PricingCompareTable,
  Support,
  Faq,
  Plans,
} from './components';

const Section = ({ children, backgroundColor, noGradient, isPlan, isFirst }) => {
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor,
        ...(noGradient ? {} : {
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${backgroundColor} 100%)`,
        }),
        py: { 
          xs: 4,
          md: isFirst ? 4 : 6
        },
        ...(isPlan && {
          pt: { xs: 3, md: 4 },
          pb: { xs: 6, md: 8 },
        }),
        transition: 'background-color 0.3s ease',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          zIndex: 2,
          '& > *': {
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            '&:hover': {
              transform: 'translateY(-4px)',
              opacity: 0.98,
            },
          },
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

const Pricing = () => {
  const theme = useTheme();

  return (
    <>
      <SEO 
        title="Pricing | +EV Bets"
        description="Choose the perfect plan for your sports betting analytics needs. Get access to real-time betting analytics, advanced statistical models, and expert recommendations."
        keywords="sports betting, betting analytics, premium subscription, betting tools, statistical models, betting odds, sports analytics"
      />
      <MainLayout>
        {/* Hero Section with Value Prop */}
        <Section backgroundColor={theme.palette.alternate.main} isFirst>
          <ValueProposition />
        </Section>

        {/* Pricing Cards Section */}
        <Section backgroundColor={theme.palette.background.paper} noGradient>
          <PricingMain />
        </Section>

        {/* Support/Testimonials Section */}
        <Section backgroundColor={theme.palette.alternate.main}>
          <Support />
        </Section>

        {/* Feature Comparison Section */}
        <Section backgroundColor={theme.palette.background.paper} noGradient>
          <PricingCompareTable />
        </Section>

        {/* FAQ Section */}
        <Section backgroundColor={theme.palette.alternate.main}>
          <Faq />
        </Section>

        {/* Plan CTA Section */}
        <Section 
          backgroundColor={theme.palette.background.paper} 
          noGradient 
          isPlan
        >
          <Plans />
        </Section>
      </MainLayout>
    </>
  );
};

export default Pricing;