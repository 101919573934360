import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '../contexts/UserContext';

const TopNav = ({ colorInvert = false }) => {
  const { logout, isAuthenticated } = useAuth0();
  const { isLoading } = useUser();
  
  const isLoggedIn = isAuthenticated && !isLoading;

  return (
    <Box 
      display={'flex'} 
      justifyContent={'flex-end'} 
      alignItems={'center'}
      sx={{ 
        display: { 
          xs: 'none',  // Hide on mobile
          md: 'flex'   // Show on medium devices and up
        }
      }}
    >
      {isLoggedIn && (
        <>
          <Box marginRight={{ xs: 1, sm: 2 }}>
            <MuiLink
              underline="none"
              component={RouterLink}
              to="/dashboard"
              color={colorInvert ? 'common.white' : 'text.primary'}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover': {
                  color: 'primary.main',
                }
              }}
            >
              Dashboard
            </MuiLink>
          </Box>
          <Box marginRight={{ xs: 1, sm: 2 }}>
            <MuiLink
              underline="none"
              component={RouterLink}
              to="/account"
              color={colorInvert ? 'common.white' : 'text.primary'}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover': {
                  color: 'primary.main',
                }
              }}
            >
              Account
            </MuiLink>
          </Box>

          <Box marginRight={{ xs: 1, sm: 2 }}>
            <MuiLink
              underline="none"
              component="button"
              onClick={() => logout()}
              color={colorInvert ? 'common.white' : 'text.primary'}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                font: 'inherit',
                '&:hover': {
                  color: 'primary.main',
                }
              }}
            >
              Logout
            </MuiLink>
          </Box>
        </>
      )}
    </Box>
  );
};

TopNav.propTypes = {
  colorInvert: PropTypes.bool,
};

export default TopNav;
