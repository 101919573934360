// src/pages/Login/Login.js
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography, Alert } from '@mui/material';
import Main from '../../layouts/Main';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const verified = searchParams.get('verified') === 'true';
  const needsVerification = searchParams.get('verify') === 'true';
  
  useEffect(() => {
    // Only redirect if not showing verification message
    if (!needsVerification) {
      loginWithRedirect({
        appState: { 
          returnTo: '/dashboard',
          verified: verified
        }
      });
    }
  }, [loginWithRedirect, verified, needsVerification]);

  return (
    <Main>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
          gap: 3
        }}
      >
        {needsVerification ? (
          <>
            <Alert severity="info" sx={{ maxWidth: 500 }}>
              Please check your email and verify your account before logging in.
              We've sent you a verification email with instructions.
            </Alert>
            <Typography variant="body1">
              Once verified, you can{' '}
              <Typography 
                component="span" 
                color="primary" 
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => loginWithRedirect()}
              >
                log in here
              </Typography>
            </Typography>
          </>
        ) : (
          <>
            <CircularProgress />
            <Typography variant="h6">
              Redirecting to login...
            </Typography>
          </>
        )}
      </Box>
    </Main>
  );
};

export default Login;