import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LegalPage from 'components/LegalPage/LegalPage';
import SEO from 'components/SEO';
import seoConfig from '../../config/seo.json';

const About = () => {
  return (
    <>
      <SEO {...seoConfig.about} />
      <LegalPage 
        title="About +EV Bets"
        subtitle="Data-Driven Sports Betting Analytics"
      >
        <Typography variant="h6" gutterBottom>
          Our Mission
        </Typography>
        <Typography paragraph>
          +EV Bets helps sports bettors make more informed decisions through data-driven analytics. 
          The platform combines advanced statistical models with real-time odds comparison to identify positive expected value betting opportunities.
        </Typography>

        <Typography variant="h6" gutterBottom>
          What is +EV?
        </Typography>
        <Typography paragraph>
          +EV (Positive Expected Value) refers to bets where the probability of winning is higher than what the odds suggest. 
          Our platform helps identify these opportunities by comparing our statistical models against real-time market odds.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Our Story
        </Typography>
        <Typography paragraph>
          The journey of +EV Bets began almost a decade ago with a simple Google spreadsheet. Founder Patrick Hayes 
          would manually copy and paste FiveThirtyEight's baseball predictions and compare them against betting lines, 
          meticulously tracking which value thresholds produced the best results over time.
        </Typography>
        <Typography paragraph>
          After earning his Master of Applied Data Science from the University of Michigan, Patrick decided to transform 
          this manual process into something more powerful. +EV Bets was launched in 2023 to automate these analytical 
          processes while expanding into custom prediction models, starting with MLB player props.
        </Typography>
        <Typography paragraph>
          What began as a personal quest to find betting value has evolved into a comprehensive platform that helps others 
          make more informed betting decisions through data-driven analytics.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Beyond +EV Bets
        </Typography>
        <Typography paragraph>
          Patrick also publishes a free weekly sports analytics newsletter called{' '}
          <Link href="http://unexpectedpointsadded.com/" target="_blank" rel="noopener noreferrer">
            Unexpected Points Added
          </Link>
          , which explores interesting trends and analysis in sports.
        </Typography>

        {/* <Typography variant="h6" gutterBottom>
          Our Technology
        </Typography>
        <Typography paragraph>
          The platform features:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 3 }}>
          <li>
            <Typography>
              Updates odds in real-time from major sportsbooks
            </Typography>
          </li>
          <li>
            <Typography>
              Processes complex statistical models efficiently
            </Typography>
          </li>
          <li>
            <Typography>
              Identifies value opportunities automatically
            </Typography>
          </li>
          <li>
            <Typography>
              Provides clear, actionable insights
            </Typography>
          </li>
        </Box> */}

        {/* <Typography variant="h6" gutterBottom>
          Our Models
        </Typography>
        <Typography paragraph>
          We leverage various data sources and models:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 3 }}>
          <li>
            <Typography>
              NBA: Neil Paine's respected prediction model
            </Typography>
          </li>
          <li>
            <Typography>
              NCAAB: Bart Torvik's proven college basketball ratings
            </Typography>
          </li>
          <li>
            <Typography>
              NFL/NCAAF: Custom models incorporating key performance metrics
            </Typography>
          </li>
          <li>
            <Typography>
              MLB/NHL: Advanced analytics combining multiple statistical factors
            </Typography>
          </li>
        </Box> */}

        {/* <Typography variant="h6" gutterBottom>
          Meet the Founder
        </Typography>
        <Typography paragraph>
          Patrick Hayes is a Michigan graduate with over a decade of experience in software engineering and data analytics. 
          His work spans multiple tech companies where he's built scalable systems and data pipelines. A lifelong sports fan, 
          he combined his technical expertise with his passion for sports analytics to create +EV Bets.
        </Typography> */}

        {/* <Typography variant="h6" gutterBottom>
          Our Values
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 3 }}>
          <li>
            <Typography>
              <strong>Transparency:</strong> We're open about our methodology and data sources
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Responsibility:</strong> We promote safe and responsible betting practices
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Innovation:</strong> We continuously improve our models and technology
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Education:</strong> We help users understand the math behind betting decisions
            </Typography>
          </li>
        </Box> */}

        <Typography variant="h6" gutterBottom>
          Looking Forward
        </Typography>
        <Typography paragraph>
          The platform is constantly being improved with new features, more sports coverage, and enhanced analytics. 
          The goal is to build the most comprehensive and user-friendly sports betting analytics platform available.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact
        </Typography>
        <Typography paragraph>
          Have questions or feedback? Reach out on{' '}
          <Link href="https://x.com/PlusEV_Bets" target="_blank" rel="noopener noreferrer">
            X (Twitter)
          </Link>{' '}
          or connect with Patrick on{' '}
          <Link href="https://www.linkedin.com/in/pfhayes/" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </Link>.
        </Typography>
      </LegalPage>
    </>
  );
};

export default About; 