import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import pagesRoutes from 'pages/routes';
// import blocksRoutes from 'blocks/routes';
// import demosRoutes from 'demos/routes';
import Sitemap from 'components/Sitemap';
import Auth0Return from './components/Auth0Return';
import Dashboard from 'pages/Dashboard';
import Account from 'pages/Account';
// import ProtectedRoute from './components/ProtectedRoute';
import nbaRoutes from './pages/NBA/nbaRoutes';
import mensCBBRoutes from './pages/MensCBB/mensCBBRoutes';
import nflRoutes from './pages/NFL/nflRoutes';
import mlbRoutes from './pages/MLB/mlbRoutes';
import nhlRoutes from './pages/NHL/nhlRoutes';
import ncaafRoutes from './pages/NCAAF/ncaafRoutes';
import Terms from 'pages/Terms/Terms';
import Privacy from 'pages/Privacy/Privacy';
import ResponsibleGambling from 'pages/ResponsibleGambling/ResponsibleGambling';
import Faq from 'pages/Faq/Faq';
import { ACTIVE_SEASONS } from './config/seasons';
import About from 'pages/About';
import NFLHomePage from './pages/NFL/NFLHomePage';
import NBAHomePage from './pages/NBA/NBAHomePage';
import MLBHomePage from './pages/MLB/MLBHomePage';
import NHLHomePage from './pages/NHL/NHLHomePage';
import MensCBBHomePage from './pages/MensCBB/MensCBBHomePage';
import NCAAFHomePage from './pages/NCAAF/NCAAFHomePage';

const getBlogPriority = (path) => {
  if (path === '/blog') {
    return '0.9';  // Main blog index
  }
  if (path.includes('/blog/category')) {
    return '0.8';  // Category pages
  }
  // Individual posts - could vary by age/importance
  return '0.7';
};

export const getSitemapRoutes = () => {
  return pagesRoutes.map(route => {
    // Base configuration
    let priority = '0.8';
    let changefreq = 'daily';  // Since these are betting pages, daily updates make sense

    // Customize based on route type
    if (route.path === '/') {
      priority = '1.0';  // Homepage gets highest priority
    } else if (route.path === '/sign-up') {
      priority = '0.9';
      changefreq = 'weekly';  // Sign-up page changes less frequently
    } else if (route.path.includes('/props')) {
      priority = '0.7';  // Props pages slightly lower priority
    }

    // Sport-specific priorities (you might want to adjust these based on season)
    const sportPriorities = {
      'nfl': '0.9',
      'ncaaf': '0.85',
      'nhl': '0.8',
      'mlb': '0.8',
      'mens-cbb': '0.85'
    };

    // Set sport-specific priority if applicable
    for (const [sport, sportPriority] of Object.entries(sportPriorities)) {
      if (route.path.includes(sport)) {
        priority = sportPriority;
        break;
      }
    }

    if (route.path.includes('/blog')) {
      priority = getBlogPriority(route.path);
      changefreq = route.path === '/blog' ? 'daily' : 'weekly';
    }

    return {
      path: route.path,
      priority,
      changefreq
    };
  });
};

const Routes = () => {
  return (
    <RouterRoutes>
      {/* Auth0 callback route must be before other routes */}
      <Route path="/auth0-return" element={<Auth0Return />} />

      {pagesRoutes.map((item, i) => (
        <Route
          key={i}
          path={item.path}
          element={item.renderer()}
        />
      ))}
      {/* {blocksRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))} */}
      {/* {demosRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))} */}
      <Route path="/sitemap" element={<Sitemap />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/account" element={<Account />} />
      
      {/* Direct routes to sport home pages that bypass season check */}
      <Route path="/nfl" element={<NFLHomePage />} />
      <Route path="/nba" element={<NBAHomePage />} />
      <Route path="/mlb" element={<MLBHomePage />} />
      <Route path="/nhl" element={<NHLHomePage />} />
      <Route path="/mens-cbb" element={<MensCBBHomePage />} />
      <Route path="/ncaaf" element={<NCAAFHomePage />} />
      
      {/* Sports routes - always registered regardless of season status */}
      {/* NBA Routes - Skip the home route since we have a direct route above */}
      {nbaRoutes
        .filter(route => route.path !== '/nba')
        .map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.renderer()}
          />
        ))}
      
      {/* Men's College Basketball Routes */}
      {mensCBBRoutes
        .filter(route => route.path !== '/mens-cbb')
        .map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.renderer()}
          />
        ))}
      
      {/* NFL Routes - Skip the home route since we have a direct route above */}
      {nflRoutes
        .filter(route => route.path !== '/nfl')
        .map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.renderer()}
          />
        ))}
      
      {/* MLB Routes - Skip the home route since we have a direct route above */}
      {mlbRoutes
        .filter(route => route.path !== '/mlb')
        .map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.renderer()}
          />
        ))}
      
      {/* NHL Routes - Skip the home route since we have a direct route above */}
      {nhlRoutes
        .filter(route => route.path !== '/nhl')
        .map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.renderer()}
          />
        ))}
      
      {/* NCAAF Routes */}
      {ncaafRoutes
        .filter(route => route.path !== '/ncaaf')
        .map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.renderer()}
          />
        ))}
      
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/responsible-gambling" element={<ResponsibleGambling />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </RouterRoutes>
  );
};

export default Routes;
