// src/pages/Auth0Return/Auth0Return.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';

const Auth0Return = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  useEffect(() => {
    // Check for verification error
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
    
    if (error === 'access_denied' && errorDescription?.includes('verify your email')) {
      // Redirect to login with verify message
      navigate('/login?verify=true');
      return;
    }
    
    // If no error, continue with normal flow
    // This will be handled by Auth0 callback
  }, [navigate, searchParams, location.search]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        p: 3
      }}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Completing authentication...
      </Typography>
    </Box>
  );
};

export default Auth0Return;