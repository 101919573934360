import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from '../services/api';

// Import after exporting USER_STATES to avoid circular dependency
// import { STATE_FEATURES } from '../config/protectedContent';


// Define user states
export const USER_STATES = {
  BROWSING: 'browsing',
  FREE: 'free_user',
  PREMIUM: 'premium_user'
};

// Define feature permissions per state
export const STATE_FEATURES = {
  [USER_STATES.BROWSING]: [
    'view_basic_odds'
  ],
  [USER_STATES.FREE]: [
    'view_basic_odds',
    'view_model_predictions',
    'view_ev_data'
  ],
  [USER_STATES.PREMIUM]: [
    'view_basic_odds',
    'view_model_predictions',
    'view_ev_data',
    'view_historical_data',
    'export_data'
  ]
};

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Initialize state from localStorage if available, but validate it
  const [userState, setUserState] = useState(() => {
    try {
      const cached = localStorage.getItem('user_state');
      if (cached) {
        const parsedState = JSON.parse(cached);
        // console.log('Found cached state:', parsedState);
        
        // If we have a valid cached state with user data, use it
        if (parsedState.user && parsedState.status) {
          // console.log('Using cached state with status:', parsedState.status);
          return {
            ...parsedState,
            isLoading: false  // Don't show loading if we have cached data
          };
        }
      }
    } catch (error) {
      console.error('Error parsing cached state:', error);
    }
    
    // Default initial state if no valid cache
    // console.log('Using default browsing state');
    return {
      status: USER_STATES.BROWSING,
      isLoading: false,  // Don't show loading initially
      user: null,
      subscription: null,
      error: null,
      previousStatus: null
    };
  });

  const { isAuthenticated, isLoading: authLoading, getAccessTokenSilently, user, logout } = useAuth0();
  const { callApi } = useApi();

  // console.log('UserProvider render:', {
  //   isAuthenticated,
  //   authLoading,
  //   status: userState.status || USER_STATES.BROWSING,  // Provide fallback
  //   isLoading: userState.isLoading
  // });

  // Add near the top of the file with other state
  const [sessionWarningShown, setSessionWarningShown] = useState(false);

  // Near your other state
  const [userDetailsCache, setUserDetailsCache] = useState(null);

  // When status changes, store the previous status and update localStorage
  const updateUserState = (newState) => {
    setUserState(prev => {
      const updatedState = typeof newState === 'function' ? newState(prev) : {
        ...newState,
        previousStatus: prev.status
      };
      
      console.log('Updating user state:', {
        from: prev.status,
        to: updatedState.status,
        state: updatedState
      });
      
      if (!updatedState.isLoading && updatedState.user) {
        localStorage.setItem('user_state', JSON.stringify(updatedState));
      }
      return updatedState;
    });
  };

  useEffect(() => {
    // console.log('Auth status changed:', { 
    //   isAuthenticated, 
    //   authLoading, 
    //   status: userState.status || USER_STATES.BROWSING
    // });
  }, [isAuthenticated, authLoading, userState.status]);

  useEffect(() => {
    let isMounted = true;

    const determineUserState = async () => {
      console.log('=== User State Change ===', {
        isAuthenticated,
        email: user?.email,
        emailVerified: user?.email_verified,
        signupMethod: user?.identities?.[0]?.provider
      });

      if (authLoading) return;

      if (!isAuthenticated) {
        if (isMounted) {
          updateUserState({
            status: USER_STATES.BROWSING,
            isLoading: false,
            user: null,
            subscription: null,
            error: null
          });
        }
        return;
      }

      try {
        if (user) {
          const roles = user['https://auth.plusevbets.com/roles'] || ['free_user'];
          const initialStatus = roles.includes('premium_user') ? USER_STATES.PREMIUM : USER_STATES.FREE;
          
          // Set state once and be done
          if (isMounted && userState.status !== initialStatus) {
            updateUserState({
              status: initialStatus,
              roles: roles,
              user: {
                id: user.sub,
                email: user.email,
                name: user.name,
                roles: roles
              },
              isLoading: false
            });
          }
        }
      } catch (error) {
        console.error('UserContext error:', error);
      }
    };

    determineUserState();

    return () => { isMounted = false; };
  }, [isAuthenticated, authLoading, user]);

  // Modify the token refresh effect
  useEffect(() => {
    if (isAuthenticated) {
      const checkSession = async () => {
        try {
          // This will automatically refresh the token if needed
          await getAccessTokenSilently({
            detailedResponse: true,
            timeoutInSeconds: 60
          });
          
          // Reset warning flag on successful token refresh
          if (sessionWarningShown) {
            setSessionWarningShown(false);
          }
          
        } catch (error) {
          console.error('Session check failed:', error);
          
          if (error.error === 'login_required' || 
              error.error === 'consent_required' ||
              error.message?.includes('Missing Refresh Token') ||
              error.message?.includes('Missing refresh token')) {
            
            console.log('Session expired or refresh token missing. Logging out user...');
            
            // Clear state
            localStorage.removeItem('user_state');
            updateUserState({
              status: USER_STATES.BROWSING,
              isLoading: false,
              user: null,
              subscription: null,
              error: 'Your session has expired. Please log in again.'
            });

            // Use Auth0's logout to properly clear everything
            logout({
              returnTo: window.location.origin,
              federated: true // This ensures complete logout
            });
          }
        }
      };

      // Check session every 10 minutes
      const sessionCheck = setInterval(checkSession, 600000);
      
      // Initial check
      checkSession();

      return () => clearInterval(sessionCheck);
    }
  }, [isAuthenticated, getAccessTokenSilently, sessionWarningShown]);

  // Update the auth callback handler
  useEffect(() => {
    const handleUserAuth = async () => {
      try {
        // Add debounce/throttle
        if (userState.authCallbackInProgress) {
          console.log('Auth callback already in progress, skipping');
          return;
        }

        updateUserState(prev => ({ ...prev, authCallbackInProgress: true }));

        // Skip if we already have this user's data
        if (userState.user?.id === user.sub && userState.status === USER_STATES.FREE) {
          console.log('Skipping auth callback - user already initialized');
          return;
        }

        // Get user data from Auth0
        const userData = {
          id: user.sub,
          email: user.email,
          name: user.name,
          given_name: user.email,
          family_name: ''
        };

        // Send auth callback
        console.log('Sending auth callback with user data:', userData);
        const response = await callApi('auth/callback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        });

        console.log('Auth callback successful:', response);

        if (response.status === 'success') {
          updateUserState(prev => ({
            ...prev,
            user: {
              ...userData,
              roles: user['https://auth.plusevbets.com/roles'] || ['free_user']
            },
            status: prev.status || USER_STATES.FREE
          }));
        }

        updateUserState(prev => ({ ...prev, authCallbackInProgress: false }));
      } catch (error) {
        updateUserState(prev => ({ ...prev, authCallbackInProgress: false }));
        console.error('Auth callback error:', error);
      }
    };

    if (user && (!userState.user || user.sub !== userState.user.sub)) {
      handleUserAuth();
    }
  }, [isAuthenticated, user, userState.user, userState.status]);

  const value = {
    ...userState,
    isLoading: userState.isLoading,
    isBrowsing: () => (userState.status || USER_STATES.BROWSING) === USER_STATES.BROWSING,
    isFree: () => userState.status === USER_STATES.FREE,
    isPremium: () => userState.status === USER_STATES.PREMIUM,
    hasRole: (requiredRole) => {
      const currentRole = userState.status || USER_STATES.BROWSING;
      if (requiredRole === USER_STATES.PREMIUM) {
        return currentRole === USER_STATES.PREMIUM;
      }
      return currentRole === USER_STATES.FREE || currentRole === USER_STATES.PREMIUM;
    },
    hasFeatureAccess: (feature) => {
      const currentStatus = userState.status || USER_STATES.BROWSING;
      // console.log('hasFeatureAccess detailed check:', {
      //   feature,
      //   status: currentStatus,
      //   features: STATE_FEATURES[currentStatus],
      //   userState: userState,
      //   result: STATE_FEATURES[currentStatus]?.includes(feature)
      // });
      const currentFeatures = STATE_FEATURES[currentStatus];
      return currentFeatures.includes(feature);
    },
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext); 