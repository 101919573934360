import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Divider, Button, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Paper, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import NewsletterWithCard from '../IndexPage/components/NewsletterWithCard/NewsletterWithCard';

// This component is NOT wrapped with withSeasonCheck so it's always visible
const NFLHomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Main>
      <Helmet>
        <title>NFL Betting Guide: Find Value Bets & Odds | +EV Bets</title>
        <meta name="description" content="Expert NFL betting analysis, strategies, and odds comparison. Find the best value bets for NFL games with our data-driven approach to football wagering." />
        <meta name="keywords" content="NFL betting, football betting strategy, NFL odds, NFL value bets, NFL betting guide, NFL betting tips" />
        <link rel="canonical" href="https://plusevbets.com/nfl" />
        <meta property="og:title" content="NFL Betting Guide: Find Value Bets & Odds | +EV Bets" />
        <meta property="og:description" content="Expert NFL betting analysis, strategies, and odds comparison. Find the best value bets for NFL games with our data-driven approach to football wagering." />
        <meta property="og:url" content="https://plusevbets.com/nfl" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsOrganization",
              "name": "National Football League",
              "alternateName": "NFL",
              "url": "https://plusevbets.com/nfl",
              "description": "NFL betting analysis and value bets"
            }
          `}
        </script>
      </Helmet>

      {/* Improved Hero Section */}
      <Box 
        sx={{
          background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 6, md: 10 },
          mb: { xs: 4, md: 6 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box>
                <Typography 
                  variant="h1" 
                  component="h1" 
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '3.5rem' }, 
                    fontWeight: 800, 
                    color: 'white',
                    mb: 2,
                    lineHeight: 1.2
                  }}
                >
                  NFL Betting Analysis & Value Finder
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h2" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }, 
                    fontWeight: 400, 
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 4,
                    maxWidth: '90%'
                  }}
                >
                  Data-driven insights to help you find positive expected value in NFL betting markets
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Button 
                    component={Link} 
                    to="/nfl/moneyline" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NFL Moneylines
                  </Button>
                  <Button 
                    component={Link} 
                    to="/nfl/spread" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NFL Spreads
                  </Button>
                  <Button 
                    component={Link} 
                    to="/nfl/total" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NFL Totals
                  </Button>
                </Box>
              </Box>
            </Grid>
            {isMd && (
              <Grid item md={5}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box 
                    sx={{
                      width: 300,
                      height: 300,
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <SportsFootballIcon 
                      sx={{ 
                        fontSize: 180, 
                        color: 'rgba(255, 255, 255, 0.8)'
                      }} 
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '15%',
            minHeight: 50
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        {/* Main Content Grid */}
        <Grid container spacing={4}>
          {/* Left Column - Main Content */}
          <Grid item xs={12} md={8}>
            {/* Introduction Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NFL Betting: The Ultimate Guide
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                The National Football League (NFL) is America's most popular sport for betting, with billions wagered each season. 
                With only 16-17 regular season games per team, each matchup receives intense scrutiny from oddsmakers and bettors alike.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we analyze NFL betting markets to find positive expected value opportunities. Our data-driven approach 
                helps you identify when the odds are in your favor, giving you an edge over the sportsbooks.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                Whether you're betting on point spreads, moneylines, totals, or player props, our NFL betting tools provide the insights 
                you need to make informed decisions.
              </Typography>
            </Paper>

            {/* NFL Predictions Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NFL Predictions & Analysis
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we provide NFL betting insights by leveraging high-quality prediction data from trusted external sources. 
                Rather than developing our own predictive model, we focus on analyzing and presenting the most reliable NFL predictions in an accessible format.
              </Typography>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Our Approach
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Curated Data Sources</Typography>}
                          secondary="We carefully select prediction data from established statistical models"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Market Comparison</Typography>}
                          secondary="We compare predictions against current betting markets"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Value Identification</Typography>}
                          secondary="We highlight where the market may have mispriced outcomes"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Clear Presentation</Typography>}
                          secondary="We present complex data in an easy-to-understand format"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Finding Value
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                      We help you identify value bets by comparing statistical predictions with sportsbook odds. A value bet occurs when:
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1, mb: 2 }}>
                      <Typography sx={{ fontFamily: 'monospace', mb: 1 }}>
                        Predicted Probability {'>'} Implied Probability from Odds
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This suggests the market has undervalued the true likelihood of an outcome
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      By focusing on these discrepancies, you can make more informed betting decisions based on statistical advantage rather than gut feeling.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                For detailed NFL predictions and value bets, visit our <Link to="/nfl/moneyline" style={{ color: theme.palette.primary.main, fontWeight: 600 }}>NFL Moneyline</Link> page where we present the latest data and highlight potential value opportunities. Our goal is to provide you with the tools and information needed to make smarter betting decisions, even if you're not a statistical expert.
              </Typography>
            </Paper>

            {/* NFL Betting Strategy Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NFL Betting Strategies
              </Typography>
              
              <Accordion defaultExpanded sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Point Spread Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Point spreads are the most popular way to bet on NFL games. The spread equalizes the matchup by giving points to the underdog or taking points from the favorite.
                  </Typography>
                  <Typography paragraph>
                    <strong>Key factors to consider when betting NFL spreads:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Home field advantage typically worth 2-3 points" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Weather conditions can significantly impact totals and spreads" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Key numbers: 3, 7, 10, 14 (common margins of victory)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Line movement and reverse line movement signals" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nfl/spread" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NFL Spread Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Moneyline Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Moneyline betting simply involves picking the winner of the game, with no point spread involved. The odds reflect the probability of each team winning.
                  </Typography>
                  <Typography paragraph>
                    <strong>Effective moneyline betting approaches:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Finding value in underdogs (especially +150 to +300 range)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Identifying overvalued favorites" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Understanding situational spots (look-ahead games, trap games)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Comparing moneyline to implied win probability" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nfl/moneyline" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NFL Moneyline Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Totals (Over/Under) Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Totals betting involves wagering on whether the combined score of both teams will go over or under a number set by oddsmakers.
                  </Typography>
                  <Typography paragraph>
                    <strong>Factors that influence NFL totals:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Weather conditions (wind, precipitation, temperature)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Offensive and defensive efficiency metrics" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Pace of play and offensive philosophy" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Injuries to key offensive players" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/nfl/total" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NFL Totals Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Historical Trends Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NFL Betting Trends & Data Analysis
              </Typography>
              <Typography paragraph sx={{ mb: 3 }}>
                Understanding historical NFL betting trends can provide valuable context for your wagering decisions. While past performance doesn't guarantee future results, certain patterns have shown consistency over time.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Home Underdogs
                      </Typography>
                      <Typography variant="body2">
                        Home underdogs of +3 or more have covered the spread at a 53.2% rate over the past decade, making them one of the most profitable situational bets in the NFL.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Divisional Rematches
                      </Typography>
                      <Typography variant="body2">
                        Teams that lost by 10+ points in the first divisional matchup cover the spread 58.7% of the time in the rematch, suggesting value in revenge situations.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Primetime Unders
                      </Typography>
                      <Typography variant="body2">
                        Primetime games (Sunday, Monday, Thursday night) have gone under the total 54.1% of the time since 2015, possibly due to increased defensive preparation.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Line Movement
                      </Typography>
                      <Typography variant="body2">
                        When the line moves against the public (reverse line movement), the side the line moves toward covers 56.3% of the time, indicating sharp money influence.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Right Column - Sidebar */}
          <Grid item xs={12} md={4}>
            {/* Quick Links Card */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                NFL Betting Markets
              </Typography>
              <List sx={{ bgcolor: theme.palette.grey[50], borderRadius: 2, overflow: 'hidden' }}>
                <ListItem 
                  component={Link} 
                  to="/nfl/moneyline" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NFL Moneylines</Typography>} 
                    secondary="Find value in picking straight-up winners" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/nfl/spread" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NFL Point Spreads</Typography>} 
                    secondary="Analyze handicapped matchups and line value" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/nfl/total" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NFL Totals</Typography>} 
                    secondary="Over/under predictions based on scoring models" 
                  />
                </ListItem>
              </List>
            </Paper>

            {/* NFL Betting FAQ */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                NFL Betting FAQ
              </Typography>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What is the most profitable way to bet on NFL games?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    The most profitable approach to NFL betting is finding value bets where the odds don't accurately reflect the true probability of outcomes. This requires comparing your own assessment of game probabilities against the implied probabilities from sportsbook odds. Our analysis tools help identify these value opportunities across different bet types.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How important is line shopping for NFL betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Line shopping is crucial for NFL betting profitability. Finding the best available odds can often be the difference between a winning and losing season. Even half-point differences on spreads can significantly impact your results over time, especially with key numbers like 3 and 7. We recommend having accounts at multiple sportsbooks to maximize your edge.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What bankroll management strategy works best for NFL betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    For NFL betting, we recommend risking 1-3% of your bankroll per wager, depending on your confidence level and the perceived edge. With a limited number of games each week, it's important to be selective rather than betting on every game. Focus on quality over quantity, and adjust your bet sizing based on the expected value of each opportunity.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How do weather conditions affect NFL betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Weather can significantly impact NFL games and betting markets. Wind has the strongest effect, particularly on passing and kicking. Games with winds over 15 mph see decreased scoring and passing efficiency. Heavy rain or snow can lead to fewer points and more running plays. Extreme cold (below 20°F) typically favors the under. Always check weather forecasts before placing bets.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
        
        {/* Newsletter CTA Section */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterWithCard />
        </Box>
      </Container>
    </Main>
  );
};

export default NFLHomePage; 