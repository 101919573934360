import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import SportsBookIcon from '@mui/icons-material/Sports';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

const metrics = [
  {
    title: 'Real-Time Updates',
    value: '<15min',
    description: 'Continuous odds monitoring',
    icon: UpdateIcon
  },
  {
    title: 'Market Coverage',
    value: '4+',
    description: 'Moneylines, spreads, totals, and props',
    icon: SportsBookIcon
  },
  {
    title: 'Daily Time Saved',
    value: '1hr+',
    description: 'Skip hours of manual research',
    icon: AccessTimeIcon
  }
];

const testimonials = [
  {
    quote: 'I used to spend hours analyzing games. +EV Bets helps me find opportunities in minutes.',
    author: 'Brian Y.',
    title: 'Sports Bettor',
    avatar: '/path/to/avatar1.jpg'  // We can add real avatars or use initials
  },
  {
    quote: 'The +EV approach has completely changed how I think about betting. Much more systematic now.',
    author: 'Rodney C.',
    title: 'Premium Member',
    avatar: '/path/to/avatar2.jpg'
  },
  {
    quote: 'Perfect for busy people who want to make data-driven bets without the time commitment.',
    author: 'Mike K.',
    title: 'Premium Member',
    avatar: '/path/to/avatar3.jpg'
  }
];

const TrustSignals = () => {
  const theme = useTheme();
  
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Data-Driven Betting
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color="text.secondary"
        >
          Make smarter bets without the time investment
        </Typography>
      </Box>
      
      <Grid container spacing={4} justifyContent="center">
        {metrics.map((metric, i) => {
          const Icon = metric.icon;
          return (
            <Grid item xs={12} md={4} key={i}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Icon 
                  sx={{ 
                    fontSize: 48, 
                    color: 'primary.main',
                    mb: 2
                  }} 
                />
                <Typography
                  variant="h3"
                  align={'center'}
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                >
                  {metric.value}
                </Typography>
                <Typography
                  variant="h6"
                  align={'center'}
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  {metric.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  align={'center'}
                >
                  {metric.description}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      
      <Box marginTop={8} marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          What Our Members Say
        </Typography>
      </Box>
      
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Card
              sx={{
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: 'transparent',
                border: `1px solid ${theme.palette.divider}`
              }}
            >
              <Typography
                color="text.secondary"
                sx={{ mb: 2, fontStyle: 'italic' }}
              >
                "{testimonial.quote}"
              </Typography>
              <Box sx={{ mt: 'auto', display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={testimonial.avatar}
                  sx={{ width: 40, height: 40, mr: 2 }}
                >
                  {testimonial.author.split(' ').map(n => n[0]).join('')}
                </Avatar>
                <Box>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {testimonial.author}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {testimonial.title}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TrustSignals;
