// import { useEffect, useState } from 'react';
// import { useSearchParams, useNavigate } from 'react-router-dom';
// import { Box, Container, Typography, CircularProgress, Alert } from '@mui/material';

// const EmailConfirmation = () => {
//   const [searchParams] = useSearchParams();
//   const navigate = useNavigate();
//   const [status, setStatus] = useState('confirming'); // confirming, success, error
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const confirmEmail = async () => {
//       try {
//         const token = searchParams.get('token');
//         if (!token) {
//           throw new Error('No confirmation token provided');
//         }

//         const response = await fetch(`/api/confirm-email?token=${token}`);
//         const data = await response.json();

//         if (!response.ok) {
//           throw new Error(data.error || 'Confirmation failed');
//         }

//         setStatus('success');
//         setTimeout(() => {
//           navigate('/login');
//         }, 3000);

//       } catch (error) {
//         setStatus('error');
//         setError(error.message);
//       }
//     };

//     confirmEmail();
//   }, [searchParams, navigate]);

//   return (
//     <Container maxWidth="sm">
//       <Box sx={{ mt: 8, textAlign: 'center' }}>
//         {status === 'confirming' && (
//           <>
//             <CircularProgress />
//             <Typography variant="h6" sx={{ mt: 2 }}>
//               Confirming your email...
//             </Typography>
//           </>
//         )}

//         {status === 'success' && (
//           <Alert severity="success">
//             Email confirmed successfully! You will be redirected to login shortly.
//           </Alert>
//         )}

//         {status === 'error' && (
//           <Alert severity="error">
//             {error || 'An error occurred during confirmation.'}
//           </Alert>
//         )}
//       </Box>
//     </Container>
//   );
// };

// export default EmailConfirmation; 
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button, Alert } from '@mui/material';
import Main from '../../layouts/Main';

const EmailVerified = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/login');
  };

  // Auto-redirect after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 5000);
    
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Main>
      <Container maxWidth="sm">
        <Box sx={{ 
          mt: 8, 
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3
        }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Your email has been verified successfully!
          </Alert>
          
          <Typography variant="h4" gutterBottom>
            Email Verified
          </Typography>
          
          <Typography variant="body1" paragraph>
            Thank you for verifying your email address. You can now log in to access all features of +EV Bets.
          </Typography>
          
          <Typography variant="body2" color="text.secondary">
            You will be redirected to the login page in a few seconds...
          </Typography>
          
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleContinue}
            size="large"
            sx={{ mt: 2 }}
          >
            Continue to Login
          </Button>
        </Box>
      </Container>
    </Main>
  );
};

export default EmailVerified; 