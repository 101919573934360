import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    title: 'How does +EV Bets find profitable opportunities?',
    subtitle: 'We leverage proven prediction models and analyze their historical performance against current odds to identify bets where the true probability of winning exceeds the implied probability from the odds.'
  },
  {
    title: 'What is +EV (Positive Expected Value)?',
    subtitle: 'Positive Expected Value means the true probability of winning is higher than what the odds suggest. For example, if our models show a team has a 60% chance of winning, but the odds imply only a 50% chance, that\'s a +EV bet.'
  },
  {
    title: 'How should I use these predictions?',
    subtitle: 'Our predictions are tools to help inform your betting decisions. We recommend using proper bankroll management and only betting when our models show significant edge over the current odds.'
  },
  {
    title: 'What sports are covered?',
    subtitle: 'We currently provide analytics for NFL, NBA, MLB, and NCAA sports, with more sports being added regularly.'
  },
  {
    title: 'Which sportsbooks do you support?',
    subtitle: 'We currently analyze odds from FanDuel and DraftKings, with plans to add more books and odds comparison features in future updates.'
  },
  {
    title: 'How often is data updated?',
    subtitle: 'Our system continuously monitors odds and updates recommendations in near real-time as new data becomes available.'
  },
  {
    title: 'What\'s included in Premium?',
    subtitle: 'Premium members get access to all sports analytics, advanced statistics, real-time updates, and priority support.'
  },
  {
    title: 'Can I cancel my subscription anytime?',
    subtitle: 'Yes, you can cancel your subscription at any time. If you cancel, you\'ll continue to have access until the end of your billing period.'
  },
  {
    title: 'Is there a money-back guarantee?',
    subtitle: 'Yes, we offer a 30-day money-back guarantee if you\'re not completely satisfied with our Premium service.'
  }
];

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Frequently Asked Questions
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={8}>
          {faqs.map((item, i) => (
            <Accordion 
              key={i} 
              sx={{ 
                mb: 1,
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-${i}-content`}
                id={`faq-${i}-header`}
              >
                <Typography variant={'h6'} fontWeight={600}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="text.secondary">
                  {item.subtitle}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Faq;
