import { USER_STATES } from '../contexts/UserContext';

// Feature definitions and their requirements
export const PROTECTED_FEATURES = {
  'view_basic_odds': {
    name: 'Basic Odds',
    description: 'View current odds from major sportsbooks',
    requiredState: USER_STATES.BROWSING,
  },
  'view_model_predictions': {
    name: 'Model Predictions',
    description: 'See win probabilities from our statistical models',
    requiredState: USER_STATES.BROWSING,
    tooltip: 'Sign up to see model predictions'
  },
  'view_ev_data': {
    name: 'EV Calculations',
    description: 'Expected Value calculations for each bet',
    requiredState: USER_STATES.FREE,
    tooltip: 'Join for free to see EV calculations'
  },
  'view_historical_data': {
    name: 'Historical Data',
    description: 'Past performance and trend analysis',
    requiredState: USER_STATES.PREMIUM,
    tooltip: 'Upgrade to access historical data'
  },
  'export_data': {
    name: 'Data Export',
    description: 'Export data for custom analysis',
    requiredState: USER_STATES.PREMIUM,
    tooltip: 'Upgrade to export data'
  }
};
