import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Divider, Button, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Paper, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SchoolIcon from '@mui/icons-material/School';
import NewsletterWithCard from '../IndexPage/components/NewsletterWithCard/NewsletterWithCard';

// This component is NOT wrapped with withSeasonCheck so it's always visible
const NCAAFHomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Main>
      <Helmet>
        <title>College Football Betting Guide: Find Value Bets & Odds | +EV Bets</title>
        <meta name="description" content="Expert NCAA Football betting analysis, strategies, and odds comparison. Find the best value bets for college football games with our data-driven approach." />
        <meta name="keywords" content="college football betting, NCAA football betting, NCAAF odds, college football value bets, NCAA football betting guide" />
        <link rel="canonical" href="https://plusevbets.com/ncaaf" />
        <meta property="og:title" content="College Football Betting Guide: Find Value Bets & Odds | +EV Bets" />
        <meta property="og:description" content="Expert NCAA Football betting analysis, strategies, and odds comparison. Find the best value bets for college football games with our data-driven approach." />
        <meta property="og:url" content="https://plusevbets.com/ncaaf" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsOrganization",
              "name": "NCAA Football",
              "alternateName": "NCAAF",
              "url": "https://plusevbets.com/ncaaf",
              "description": "College football betting analysis and value bets"
            }
          `}
        </script>
      </Helmet>

      {/* Improved Hero Section */}
      <Box 
        sx={{
          background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 6, md: 10 },
          mb: { xs: 4, md: 6 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box>
                <Typography 
                  variant="h1" 
                  component="h1" 
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '3.5rem' }, 
                    fontWeight: 800, 
                    color: 'white',
                    mb: 2,
                    lineHeight: 1.2
                  }}
                >
                  College Football Betting Analysis
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h2" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }, 
                    fontWeight: 400, 
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 4,
                    maxWidth: '90%'
                  }}
                >
                  Data-driven insights to help you find positive expected value in NCAA Football betting markets
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Button 
                    component={Link} 
                    to="/ncaaf/moneyline" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NCAAF Moneylines
                  </Button>
                  <Button 
                    component={Link} 
                    to="/ncaaf/spread" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NCAAF Spreads
                  </Button>
                  <Button 
                    component={Link} 
                    to="/ncaaf/total" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    NCAAF Totals
                  </Button>
                </Box>
              </Box>
            </Grid>
            {isMd && (
              <Grid item md={5}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box 
                    sx={{
                      width: 300,
                      height: 300,
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <SportsFootballIcon 
                        sx={{ 
                          fontSize: 180, 
                          color: 'rgba(255, 255, 255, 0.8)'
                        }} 
                      />
                      <SchoolIcon 
                        sx={{ 
                          fontSize: 80, 
                          color: 'rgba(255, 255, 255, 0.9)',
                          position: 'absolute',
                          bottom: -20,
                          right: -20
                        }} 
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '15%',
            minHeight: 50
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        {/* Main Content Grid */}
        <Grid container spacing={4}>
          {/* Left Column - Main Content */}
          <Grid item xs={12} md={8}>
            {/* Introduction Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                NCAA Football Betting: The Ultimate Guide
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                NCAA Football offers one of the most diverse and opportunity-rich betting environments in sports. With over 130 FBS teams playing across multiple conferences, college football provides countless opportunities for bettors to find value throughout the season.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we analyze college football betting markets to find positive expected value opportunities. Our data-driven approach helps you identify when the odds are in your favor, giving you an edge over the sportsbooks.
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                Whether you're betting on regular season games, conference championships, or bowl games, our college football betting tools provide the insights you need to make informed decisions in this exciting and often unpredictable sport.
              </Typography>
            </Paper>

            {/* NCAAF Predictions Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                College Football Predictions & Analysis
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.05rem', mb: 2 }}>
                At +EV Bets, we provide college football betting insights by leveraging high-quality prediction data from trusted external sources. Rather than developing our own predictive model, we focus on analyzing and presenting the most reliable NCAA football predictions in an accessible format.
              </Typography>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Our Approach
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Curated Data Sources</Typography>}
                          secondary="We carefully select prediction data from established statistical models"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Market Comparison</Typography>}
                          secondary="We compare predictions against current betting markets"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Value Identification</Typography>}
                          secondary="We highlight where the market may have mispriced outcomes"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary={<Typography fontWeight={500}>Clear Presentation</Typography>}
                          secondary="We present complex data in an easy-to-understand format"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 3, bgcolor: theme.palette.grey[50], borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '1.2rem', fontWeight: 600, color: theme.palette.primary.main }}>
                      Finding Value
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                      We help you identify value bets by comparing statistical predictions with sportsbook odds. A value bet occurs when:
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1, mb: 2 }}>
                      <Typography sx={{ fontFamily: 'monospace', mb: 1 }}>
                        Predicted Probability {'>'} Implied Probability from Odds
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This suggests the market has undervalued the true likelihood of an outcome
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      By focusing on these discrepancies, you can make more informed betting decisions based on statistical advantage rather than gut feeling.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography paragraph sx={{ fontSize: '1.05rem' }}>
                For detailed college football predictions and value bets, visit our <Link to="/ncaaf/moneyline" style={{ color: theme.palette.primary.main, fontWeight: 600 }}>NCAAF Moneyline</Link> page where we present the latest data and highlight potential value opportunities. Our goal is to provide you with the tools and information needed to make smarter betting decisions, even if you're not a statistical expert.
              </Typography>
            </Paper>

            {/* NCAAF Betting Strategy Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                College Football Betting Strategies
              </Typography>
              
              <Accordion defaultExpanded sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Point Spread Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Point spreads are the most popular way to bet on college football games, especially with the significant talent disparities between teams.
                  </Typography>
                  <Typography paragraph>
                    <strong>Key factors to consider when betting college football spreads:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Home field advantage varies significantly by program" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Weather conditions and their impact on game style" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Motivational factors (rivalry games, bowl eligibility)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Situational spots (look-ahead games, letdown spots)" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/ncaaf/spread" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NCAAF Spread Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ mb: 2, '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Moneyline Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    Moneyline betting in college football can offer value, particularly when targeting specific situational spots and conference matchups.
                  </Typography>
                  <Typography paragraph>
                    <strong>Effective moneyline betting approaches:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Identifying undervalued home underdogs" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Conference games with rivalry implications" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Teams with strong defensive metrics as underdogs" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Late-season motivation disparities" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/ncaaf/moneyline" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NCAAF Moneyline Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
              
              <Accordion sx={{ '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h4" component="h4" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    Totals (Over/Under) Betting Strategy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography paragraph>
                    College football totals betting requires understanding team tempo, offensive/defensive efficiency, and environmental factors that impact scoring.
                  </Typography>
                  <Typography paragraph>
                    <strong>Factors that influence college football totals:</strong>
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Team tempo and offensive philosophy" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Weather conditions (wind, precipitation, temperature)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Defensive efficiency and style" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Late-season fatigue and injury factors" />
                    </ListItem>
                  </List>
                  <Button component={Link} to="/ncaaf/total" color="primary" variant="contained" sx={{ mt: 2 }}>
                    View NCAAF Totals Analysis
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Historical Trends Section */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h3" component="h3" sx={{ mb: 3, fontSize: '1.75rem', fontWeight: 700 }}>
                College Football Betting Trends & Data Analysis
              </Typography>
              <Typography paragraph sx={{ mb: 3 }}>
                Understanding historical college football betting trends can provide valuable context for your wagering decisions. While past performance doesn't guarantee future results, certain patterns have shown consistency over time.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Home Underdog Value
                      </Typography>
                      <Typography variant="body2">
                        Home underdogs in conference games have historically covered the spread at a 55.3% rate, with the value increasing in games with lower totals and when the home team is coming off a loss.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Service Academy Unders
                      </Typography>
                      <Typography variant="body2">
                        Games involving service academies (Army, Navy, Air Force) have gone under the total at a 58.7% rate over the past decade, due to their run-heavy offenses that limit possessions and keep the clock moving.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Ranked vs. Unranked
                      </Typography>
                      <Typography variant="body2">
                        Unranked teams facing ranked opponents have covered the spread at a 53.1% rate when getting 17+ points, suggesting the market tends to overvalue ranked teams in perceived mismatches.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" sx={{ height: '100%', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                    <CardContent>
                      <Typography variant="h5" component="h5" sx={{ mb: 1.5, fontSize: '1.1rem', fontWeight: 600, color: theme.palette.primary.main }}>
                        Weather Impact
                      </Typography>
                      <Typography variant="body2">
                        Games with winds over 15 mph have gone under the total at a 57.2% rate, with the effect amplified to 61.8% when combined with temperatures below 40°F, creating value opportunities in late-season games.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Right Column - Sidebar */}
          <Grid item xs={12} md={4}>
            {/* Quick Links Card */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                College Football Betting Markets
              </Typography>
              <List sx={{ bgcolor: theme.palette.grey[50], borderRadius: 2, overflow: 'hidden' }}>
                <ListItem 
                  component={Link} 
                  to="/ncaaf/moneyline" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NCAAF Moneylines</Typography>} 
                    secondary="Find value in picking straight-up winners" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/ncaaf/spread" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NCAAF Point Spreads</Typography>} 
                    secondary="Analyze handicapped matchups and line value" 
                  />
                </ListItem>
                <Divider />
                <ListItem 
                  component={Link} 
                  to="/ncaaf/total" 
                  sx={{ 
                    color: 'text.primary', 
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      bgcolor: theme.palette.grey[100],
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<Typography fontWeight={600}>NCAAF Totals</Typography>} 
                    secondary="Over/under predictions based on scoring models" 
                  />
                </ListItem>
              </List>
            </Paper>

            {/* NCAAF Betting FAQ */}
            <Paper elevation={2} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 700 }}>
                College Football Betting FAQ
              </Typography>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How does betting on college football differ from the NFL?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    College football betting differs from the NFL in several key ways. There's greater variance in college due to larger talent disparities, more teams, and less public information. Point spreads can be much larger (sometimes 30+ points), and home field advantage is typically stronger and more variable. Weather impacts can be more significant due to less standardized facilities, and motivation factors like rivalries and bowl eligibility play a bigger role.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What's the best approach for betting on college football totals?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    For college football totals, focus on tempo (plays per game), offensive and defensive efficiency, and weather conditions. Teams with contrasting styles (air raid vs. triple option) create interesting dynamics. Late-season games in cold weather locations tend to go under, while early-season non-conference games between mismatched teams often go over due to garbage time scoring. Pay special attention to wind forecasts, as winds over 15 mph significantly impact passing and kicking.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>How important are situational spots in college football betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Situational spots are extremely important in college football betting due to the emotional nature of the sport and the young age of the players. Look-ahead spots (before a big game), letdown spots (after a big win), and sandwich spots (between two important games) often create value opportunities. Teams coming off bye weeks typically perform better, while teams playing their third consecutive road game often underperform expectations.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 1
                  }}
                >
                  <Typography fontWeight={600}>What advanced stats are most useful for college football betting?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    The most useful advanced stats for college football betting include success rate (percentage of plays gaining 50% of needed yards on 1st down, 70% on 2nd, 100% on 3rd/4th), explosive play rate, line yards (measuring offensive/defensive line performance), and havoc rate (percentage of plays with TFLs, forced fumbles, passes defended). Adjusted statistics that account for opponent strength are particularly valuable given the wide disparity in competition levels.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
        
        {/* Newsletter CTA Section */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterWithCard />
        </Box>
      </Container>
    </Main>
  );
};

export default NCAAFHomePage; 