import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PricingFaq from 'pages/Pricing/components/Faq';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Footer, Headline } from './components';
import { Helmet } from 'react-helmet-async';
import seoConfig from '../../config/seo';

const Faq = () => {
  const theme = useTheme();
  const { title, description, keywords } = seoConfig.faq;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Main>
        <Box>
          <Box
            sx={{
              backgroundColor: theme.palette.alternate.main,
              backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
              // marginTop: 13,
              paddingTop: 13,
            }}
          >
            <Container>
              <Headline />
            </Container>
          </Box>
          <Container maxWidth={800}>
            <PricingFaq />
          </Container>
          {/* <Box bgcolor={theme.palette.alternate.main}>
            <Container>
              <Footer />
            </Container>
          </Box> */}
        </Box>
      </Main>
    </>
  );
};

export default Faq;