import React from 'react';
import Typography from '@mui/material/Typography';
import LegalPage from 'components/LegalPage/LegalPage';
import SEO from 'components/SEO';
import seoConfig from '../../config/seo.json';

const Privacy = () => {
  return (
    <>
      <SEO {...seoConfig.privacy} />
      <LegalPage title="Privacy Policy">
        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography paragraph>
          We collect information you provide directly to us, including name, email address, 
          and account preferences. We also collect usage data to improve our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use collected information to provide and improve our services, communicate with you, 
          and ensure security of our platform. We do not sell your personal information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Data Security
        </Typography>
        <Typography paragraph>
          We implement appropriate security measures to protect your personal information. 
          However, no method of transmission over the internet is 100% secure.
        </Typography>

        {/* Add more sections as needed */}
      </LegalPage>
    </>
  );
};

export default Privacy; 