import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const steps = [
  {
    title: 'Find Games',
    subtitle: 'Browse upcoming games across NFL, NBA, MLB, and NCAA sports',
    description: 'Select from a wide range of markets including moneylines, spreads, totals, and props',
    icon: SearchIcon,
  },
  {
    title: 'Analyze Value',
    subtitle: 'Our models identify bets where true win probability exceeds the odds',
    description: 'Compare odds across FanDuel and DraftKings to find the best opportunities',
    icon: AutoGraphIcon,
  },
  {
    title: 'Make Decisions',
    subtitle: 'Place bets with confidence using data-driven insights',
    description: 'Focus on bets with positive expected value to improve your long-term results',
    icon: AccountBalanceWalletIcon,
  },
];

const HowItWorks = () => {
  const theme = useTheme();

  return (
    <Box 
      sx={{ 
        py: 8,
        backgroundColor: theme.palette.alternate.main 
      }}
    >
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          How It Works
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color="text.secondary"
        >
          Start finding profitable bets in three simple steps
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, i) => {
          const Icon = step.icon;
          return (
            <Grid item xs={12} md={4} key={i}>
              <Card
                sx={{
                  p: 4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: theme.palette.background.paper,
                }}
              >
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 2
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                      marginRight: 2,
                    }}
                  >
                    {i + 1}
                  </Box>
                  <Icon 
                    sx={{ 
                      fontSize: 40,
                      color: theme.palette.primary.main
                    }} 
                  />
                </Box>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  {step.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {step.subtitle}
                </Typography>
                <Typography
                  color="text.secondary"
                >
                  {step.description}
                </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HowItWorks; 