import { useAuth0 } from '@auth0/auth0-react';
import { API_URL } from '../config/api';
import React from 'react';

export const useApi = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const callApi = React.useCallback(async (endpoint, options = {}) => {
    try {
      // Clean up endpoint to prevent double 'api'
      const cleanEndpoint = endpoint.replace(/^\/?(api\/)?/, '');
      
      // If API_URL ends with a slash, remove it
      const baseUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
      const url = `${baseUrl}/api/${cleanEndpoint}`;
      
      let headers = {
        'Content-Type': 'application/json',
        ...options.headers
      };
      
      // Only try to get a token if the user is authenticated
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently({
            cacheMode: 'on-demand' // Force a fresh token check
          });
          
          console.log('API Call Debug:', {
            endpoint,
            hasToken: !!token,
            tokenStart: token?.substring(0, 10) + '...'
          });
          
          headers = {
            ...headers,
            'Authorization': `Bearer ${token}`
          };
        } catch (tokenError) {
          console.error('Failed to get access token:', tokenError);
          
          // If this is a refresh token error, we'll let the UserContext handle the logout
          if (tokenError.message?.includes('Missing Refresh Token') || 
              tokenError.message?.includes('Missing refresh token')) {
            console.warn('Refresh token issue detected in API call');
          }
          
          // Continue without token - the API will handle unauthorized access
        }
      } else {
        console.log('API Call Debug: User not authenticated, proceeding without token');
      }

      if (options.body && typeof options.body === 'object') {
        options.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, {
        ...options,
        headers
      });

      if (!response.ok) {
        console.error('API Error:', {
          status: response.status,
          statusText: response.statusText,
          endpoint,
          responseText: await response.text()
        });
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  return { callApi };
}; 